import { gql } from "graphql-request";
export const graphqlConfigs = {
  nfts: {
    query: gql`query nftsSearch($collection_id: Int, $collection_ids: [Int], $category: String, $token_id: Int, $name: String, $auction_status: String, $creatorEmail: String, $ownerEmail: String, $claimerEmail: String, $blockchain_ownerEmail: String, $auction_winnerEmail: String, $sort_field: String, $order: String, $start: Int, $limit: Int, $blockchain: String) {
      nftsSearch(collection_id: $collection_id, collection_ids: $collection_ids, category: $category, token_id: $token_id, name: $name, auction_status: $auction_status, creatorEmail: $creatorEmail, ownerEmail: $ownerEmail, claimerEmail: $claimerEmail, blockchain_ownerEmail: $blockchain_ownerEmail, auction_winnerEmail: $auction_winnerEmail,  sort_field: $sort_field, order: $order, start: $start, limit: $limit, blockchain: $blockchain) {
        id
        name
        collection_id
        token_id
        token_uri
        name
        blockchain
        created_at
        auction_status
        currentTime
        serverTime
        last_price
        likes
        on_chain
        isLike
        is_approved
        uploaded {
          days
          hours
          minutes
          seconds
        }
        sell {
          auction_start_at,
          auction_end_at
        }
        owner {
          id
          username
          image
          email
        }
      }
    }`,
    name: 'nfts',
    url: 'nfts/fetch',
  },
  auction: {
    query: gql`query nftcardFromTokenId($collection_id: Int, $token_id: Int, $user: Int) {
      nftcardFromTokenId(collection_id: $collection_id, token_id: $token_id, user: $user) {
        id
        name
        description
        collection_name
        collection_id
        chain_token_uri
        token_id
        token_uri
        on_chain
        auction_status
        likes
        currentTime
        serverTime
        enableClaim
        is_approved
        isLike
        isBookmark
        uploaded {
          days
          hours
          minutes
          seconds
        }
        sell {
          auction_start_at,
          auction_end_at
        }
        last_price
        blockchain
        collection {
          id
          name
          contract
          creator_wallet_address
          royalty
        }
        owner {
          id
          username
          image
          wallet_address
          email
        }
        blockchain_owner {
          id
          username
          image
          wallet_address
          email
        }
        collect {
          auction_start_at
          auction_end_at
          uid
          price
          signature
          wallet_address
        }
        auction_winner {
          id
          username
          email
          wallet_address
        }
        bids {
          id
          bid_amount
          created_at
          at
          user {
            email
            username
          }
          nft {
            last_price
          }
        }
        history {
          maker {
            id
            username
            image
            email
          }
          sell_status
          created_at
          price
        }
      }
    }`,
    name: 'auction',
    url: 'nfts/auction',
  },
  token: {
    query: gql`query nftcardFromTokenId($collection_id: Int, $token_id: Int, $user: Int) {
      nftcardFromTokenId(collection_id: $collection_id, token_id: $token_id, user: $user) {
        id
        name
        collection_id
        collection_name
        token_id
        token_uri
        description
        last_price
        on_chain
        blockchain
        isLike
        isBookmark
        chain_token_uri
        auction_status
        likes
        is_approved
        uploaded {
          days
          hours
          minutes
          seconds
        }
        collection {
          id
          creator_wallet_address
          name
          contract
          royalty
        }
        sell {
          auction_start_at
          auction_end_at
          auction_status
          sell_type
        }
        creator {
          id
          username
          image
          wallet_address
          email
        }
        owner {
          id
          username
          image
          wallet_address
          email
        }
        blockchain_owner {
          id
          username
          image
          wallet_address
          email
        }
        history {
          maker {
            id
            username
            image
            email
          }
          sell_status
          created_at
          price
        }
        bids {
          bid_amount
          at
          user {
            id
            username
            image
            email
          }
          created_at
        }
      }
    }`,
    name: 'token',
    url: 'nfts/token',
  },
  bookmark: {
    query: gql`query bookmark($searchTxt: String, $auction_status: String, $email: String) {
      bookmark(searchTxt: $searchTxt, auction_status: $auction_status, email: $email) {
        id
        collection_id
        token_id
        nft {
          id
          name
          collection_id
          collection_name
          token_id
          token_uri
          name
          blockchain
          chain_token_uri
          likes
          created_at
          auction_status
          currentTime
          serverTime
          last_price
          uploaded {
            days
            hours
            minutes
            seconds
          }
          owner {
            id
            username
            image
            email
          }
        }
        user {
          id
          username
          image
          email
        }
      }
    }`,
    name: 'bookmarks',
    url: 'nfts/bookmarks',
  },
  like: {
    query: gql`query like($searchTxt: String, $auction_status: String, $email: String) {
      like(searchTxt: $searchTxt, auction_status: $auction_status, email: $email) {
        id
        collection_id
        token_id
        nft {
          id
          name
          collection_id
          collection_name
          token_id
          token_uri
          name
          blockchain
          chain_token_uri
          created_at
          auction_status
          likes
          currentTime
          serverTime
          last_price
          uploaded {
            days
            hours
            minutes
            seconds
          }
          owner {
            id
            username
            image
            email
          }
        }
        user {
          id
          username
          image
          email
        }
      }
    }`,
    name: 'like',
    url: 'nfts/like',
  },
  createNfts: {
    query: gql`mutation nftsCreate($id: ID, $name: String, $token_uri: String, $blockchain: String, $category: String, $chain_token_uri: String, $collection_id: Int, $collection_name: String, $description: String, $file_type: String, $icon: String, $imported: Boolean, $last_price: Float, $likes: Int, $link: String, $on_chain: Boolean, $quantity: Int, $royalty: Int, $token_id: Int, $contract: String) {
      createNFT(id: $id, name: $name, token_uri: $token_uri, blockchain: $blockchain, category: $category, chain_token_uri: $chain_token_uri, collection_id: $collection_id, collection_name: $collection_name, description: $description, file_type: $file_type, icon: $icon, imported: $imported, last_price: $last_price, likes: $likes, link: $link, on_chain: $on_chain, quantity: $quantity, royalty: $royalty, token_id: $token_id, contract: $contract) {
        id
        token_id
        name
        collection {
          id
          name
          contract
        }
      }
    }`,
    name: 'createNfts',
    url: 'nfts/createNft',
  },
  collections: {
    query: gql`query collectionsSearch($id: ID, $creatorId: Int, $blockchain: String, $category: String, $name: String, $sort_field: String, $order: String, $start: Int, $limit: Int) {
      collectionsSearch(id: $id, creatorId: $creatorId, blockchain: $blockchain, category: $category, name: $name, sort_field: $sort_field, order: $order, start: $start, limit: $limit) {
        id
        logo
        name
        category
        totalNft
        blockchain
        uploaded {
          days
          hours
          minutes
          seconds
        }
        creator {
          id
          username
          image
        }
      }
    }`,
    name: 'collections',
    url: 'collections/fetch',
  },
  collection: {
    query: gql`query collection($id: Int!) {
      collection(id: $id) {
        logo
        name
        feature
        banner
        url
        description
        category
        site
        discord
        instagram
        reddit
        twitter
        youtube
        blockchain
      }
    }`,
    name: 'collection',
    url: 'collection/fetch',
  },
  statistics: {
    query: gql`query {
      statistics {
        totalNFTs
        totalAuctions
        totalSellers
      },
      trendingNFTs {
        id
        token_uri
        name
        last_price
        token_id
        collection_id
        likes
        uploaded {
          days
          hours
          minutes
          seconds
        }
        owner {
          image
          username
        }
        currentTime
        serverTime
        created_at
      },
      trendingUsers {
        id
        username
        email
        bio
        image
        ownedCount
        createdCount
      }
    }`,
    name: 'statistics',
    url: 'nft/statistics',
  },
  trendingNfts: {
    query: gql`trendingNFTs {
      id
      token_uri
      name
      last_price
      token_id
      collection_id
      likes
      uploaded {
        days
        hours
        minutes
        seconds
      }
      creator{
        image
        username
      }
      owner {
        image
        username
      }
      currentTime
      serverTime
      created_at
    }`,
    name: 'trendingNFTs',
    url: 'nft/trendingNFTs'
  },
  getTrendingUsers: {
    query: gql`query trendingUsers{
      trendingUsers {
        id
        email
        username
        bio
        image
        ownedCount
        createdCount
      }
    }`,
    name: 'trendingUsers',
    url: 'nft/trendingUsers',
  },
  getUserHistory: {
    query: gql`query getUserHistory($email: String, $page: Int, $limit: Int){
      getUserHistory(email: $email, page: $page, limit: $limit) {
        totalCount
        limit
        page
        pageCount
        histories {
          id
          sell_status
          price
          created_at
          nft {
            name
          }
        }
      }
    }`,
    name: 'getUserHistory',
    url: 'nfts/getUserHistory',
  },
  userInfo: {
    query: gql`query userInfo{
      userInfo {
        id
        email
        username
        bio
        role
        image
        is_auto_bid
        wallet_address
      }
    }`,
    name: 'userInfo',
    url: 'nft/userInfo',
  },
  userInfoById: {
    query: gql`query userInfoById($user_id: Int){
      userInfoById(user_id: $user_id) {
        id
        email
        username
        bio
        image
      }
    }`,
    name: 'userInfoById',
    url: 'nft/userInfoById',
  },
  createCollections: {
    query: gql`mutation addCollection($id: ID, $name: String, $logo: String, $featured: String, $banner: String, $url: String, $description: String, $category: String, $site: String, $discord: String, $instagram: String, $reddit: String, $twitter: String, $youtube: String, $blockchain: String, $payment: String, $theme: String, $royalty: Int, $contract: String, $imported: Boolean) {
      addCollection(id: $id, name: $name, logo: $logo, featured: $featured, banner: $banner, url: $url, description: $description, category: $category, site: $site, discord: $discord, instagram: $instagram, reddit: $reddit, twitter: $twitter, youtube: $youtube, blockchain: $blockchain, payment: $payment, theme: $theme, royalty: $royalty, contract: $contract, imported: $imported) {
        id
      }
    }`,
    name: 'createNfts',
    url: 'nfts/createNft',
  },
  updateCollection: {
    query: gql`mutation updateCollection($id: ID, $name: String, $logo: String, $featured: String, $banner: String, $url: String, $description: String, $category: String, $site: String, $discord: String, $instagram: String, $reddit: String, $twitter: String, $youtube: String, $blockchain: String, $payment: String, $theme: String, $royalty: Int, $contract: String, $imported: Boolean) {
      updateCollection(id: $id, name: $name, logo: $logo, featured: $featured, banner: $banner, url: $url, description: $description, category: $category, site: $site, discord: $discord, instagram: $instagram, reddit: $reddit, twitter: $twitter, youtube: $youtube, blockchain: $blockchain, payment: $payment, theme: $theme, royalty: $royalty, contract: $contract, imported: $imported) {
        id
      }
    }`,
    name: 'updateCollection',
    url: 'nfts/updateCollection',
  },
  updateSell: {
    query: gql`mutation updateSell($auction_start_at: Int, $wallet_address: String, $auction_end_at: Int, $price: Float, $collection_id: Int, $token_id: Int, $signature: String, $uid: String, $sell_type: String, $todayTmp: Int) {
      updateSell(auction_start_at: $auction_start_at, wallet_address: $wallet_address, auction_end_at: $auction_end_at, price: $price, collection_id: $collection_id, token_id: $token_id, signature: $signature, uid: $uid, sell_type: $sell_type, todayTmp: $todayTmp) {
        success
        message
      }
    }`,
    name: 'updateCollection',
    url: 'nfts/updateCollection',
  },
  updateSellStatus: {
    query: gql`mutation updateSellStatus($auction_start_at: Int, $wallet_address: String, $auction_end_at: Int, $price: Float, $collection_id: Int, $token_id: Int, $signature: String, $uid: String, $sell_type: String, $todayTmp: Int) {
      updateSellStatus(auction_start_at: $auction_start_at, wallet_address: $wallet_address, auction_end_at: $auction_end_at, price: $price, collection_id: $collection_id, token_id: $token_id, signature: $signature, uid: $uid, sell_type: $sell_type, todayTmp: $todayTmp) {
        id
      }
    }`,
    name: 'updateCollection',
    url: 'nfts/updateCollection',
  },
  histories: {
    query: gql`query getHistories() {
      historySearch() {

      }
    }`,
    name: 'histories',
    url: 'histories/fetch',
  },
  pendingPool: {
    query: gql`query getPendingPool($maker:Int, $email: String, $page: Int, $limit: Int ) {
      getPendingPool(maker: $maker, email: $email, page: $page, limit: $limit) {
        balance,
        getPendingPoolHistory {
          type
          amount
          created_at
          token_name
        }
      }
    }`,
    name: 'pendingPool',
    url: 'pendingPool/fetch',
  },
  addLike: {
    query: gql`mutation addLike($collection_id: Int!, $token_id: Int!) {
      addLike(collection_id: $collection_id, token_id: $token_id) {
        success
        message
      }
    }`,
    name: 'addLike',
    url: 'nfts/addLike',
  },
  addBookmark: {
    query: gql`mutation addBookmark($collection_id: Int!, $token_id: Int!) {
      addBookmark(collection_id: $collection_id, token_id: $token_id) {
        success
        message
      }
    }`,
    name: 'addBookmark',
    url: 'nfts/addBookmark',
  },
  addBid: {
    query: gql`mutation addBid($collection_id: Int!, $token_id: Int!) {
      addBid(collection_id: $collection_id, token_id: $token_id) {
        status
        message
        normal_bids
      }
    }`,
    name: 'addBookmark',
    url: 'nfts/addBid',
  },
  setAutoBid: {
    query: gql`mutation setAutoBid($user_id: Int) {
      setAutoBid(user_id: $user_id) {
        success,
        message
      }
    }`,
    name: 'setAutoBid',
    url: 'nfts/setAutoBid',
  },
  claim: {
    query: gql`mutation claim($collection_id: Int!, $token_id: Int!) {
      claim(collection_id: $collection_id, token_id: $token_id) {
        success
        message
      }
    }`,
    name: 'claim',
    url: 'nfts/claim',
  },
  collect: {
    query: gql`mutation collect($collection_id: Int!, $token_id: Int!) {
      collect(collection_id: $collection_id, token_id: $token_id) {
        success
        message
      }
    }`,
    name: 'collect',
    url: 'nfts/collect',
  },
  setOnChain: {
    query: gql`mutation setOnChain($collection_id: Int!, $token_id: Int!) {
      setOnChain(collection_id: $collection_id, token_id: $token_id) {
        success
        message
      }
    }`,
    name: 'setOnChain',
    url: 'nfts/setOnChain',
  },
  setIsApproved: {
    query: gql`mutation setIsApproved($collection_id: Int!, $token_id: Int!) {
      setIsApproved(collection_id: $collection_id, token_id: $token_id) {
        success
        message
      }
    }`,
    name: 'setIsApproved',
    url: 'nfts/setIsApproved',
  },
  withdraw: {
    query: gql`mutation withdraw($amount: Float) {
      withdraw(amount: $amount) {
        balance
      }
    }`,
    name: 'withdraw',
    url: 'nfts/withdraw',
  },
};

export const BID_ADDED_SUBSCRIPTION = gql`
    subscription bidAdded($room: String!) {
        bidAdded(room: $room) {
            bid_amount
            created_at
            bid_at {
              dateTime
              millisecond
            }
            user {
                email
                username
            }
            nft {
                last_price
                auction_end_at
            }
        }
    }
`;

export const ALERT_PAY_SUBSCRIPTION = gql`
    subscription alertPay($email: String!) {
        alertPay(email: $email) {
            email
            amount
        }
    }
`;

export const AUCTION_ENDED_SUBSCRIPTION = gql`
    subscription auctionEnded($room: String!) {
      auctionEnded(room: $room) {
            id
            auction_start_at
            auction_end_at
            auction_status
            currentTime
            serverTime
            enableClaim
            auction_winner {
              id
              username
              email
              wallet_address
            }
        }
    }
`;
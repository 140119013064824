import { Divider } from "@mui/material";
import InputButtonComponent from "components/NftCard/NftCardDetails/InputButtonComponent";
import InputDateRangePicker from "components/NftCard/NftCardDetails/InputDateRangePicker";

import styles from "components/NftCard/NftCardDetails/NftWork.module.css";

export const FixedComponent = ({
  darkMode,
  tokenInfo,
  price,
  handleStateChange,
}) => {
  const attrData = {
    label: "PRICE",
    type: "number",
    placeholder: "CREATE_ASSET_PLACEHOLDER_ENTER_NFT_BASE_PRICE",
    name: "price",
    buttonLabel: "US Dollar",
    color: `${darkMode ? "#ffffff" : "#121212"}`,
    defaultValue: tokenInfo?.last_price,
    handleChange: (e) => {
      const { name, value } = e.target;
      handleStateChange(name, value);
    },
  };

  return (
    <>
      <InputButtonComponent attr={attrData} />
      <InputDateRangePicker handleChange={handleStateChange} />
      <Divider className={styles.dividerBox} />
    </>
  );
};


export const getDateTimeDifference = (startDateTime, endDateTime) => {
  // Calculate the difference in milliseconds
  const diffInMs = Math.abs(endDateTime - startDateTime);

  // Calculate the number of days
  const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

  // Calculate the remaining hours
  const hours = Math.floor((diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

  // Calculate the remaining minutes
  const minutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));

  // Calculate the remaining seconds
  const seconds = Math.floor((diffInMs % (1000 * 60)) / 1000);

  return {
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds
  };
}

export const getTodayTmp = () => {
  const today = new Date(
    new Date().getUTCFullYear(),
    new Date().getUTCMonth(),
    new Date().getUTCDate(),
  );

  return Math.floor(today.getTime() / 1000);
}

export const getDateFromTmp = (baseTmp, calculuateTmp) => {
  return calculuateTmp - baseTmp + getTodayTmp();
}

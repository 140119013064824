import { useTheme } from "@emotion/react";
import { lazy, Suspense } from "react";
import { Box, Container, useMediaQuery } from "@mui/material";
import React from "react";

const Navigation = lazy(() => import("../Navigation/Navigation"));
const Footer = lazy(() => import("../Footer/Footer"));
const SideBar = lazy(() => import("../SideBar/SideBar"));

const Layout = ({ children, darkMode }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <React.Fragment>
      <Suspense fallback={<div></div>}>
        <Navigation darkMode={darkMode} />
      </Suspense>
      <Box
        sx={
          darkMode
            ? { backgroundColor: "#040404" }
            : { backgroundColor: "#ffffff" }
        }
      >
        {isMobile && (
          <Suspense fallback={<div></div>}>
            <SideBar darkMode={darkMode} />
          </Suspense>
        )}
        <Box sx={isMobile ? { py: 10 } : { mt: -10 }}>{children}</Box>
      </Box>
      <Suspense fallback={<div></div>}>
        {!isMobile && <Footer darkMode={darkMode} />}
      </Suspense>
    </React.Fragment>
  );
};

export default Layout;

import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";

export default function TextAreaExtend({
    darkMode,
    name,
    label,
    description,
    handleChange,
    defaultValue
}) {
    const { t } = useTranslation();

    return (
        <>
            <TextField
                name={name}
                label={t(label)}
                required
                multiline
                color="secondary"
                style={{
                    resize: "vertical",
                    minHeight:"100px"
                }}
                defaultValue={defaultValue}
                onChange={handleChange}
            />
            {description ? (<label
                style={{
                    color: `${darkMode ? "#c8c8c8" : "#202020"}`,
                    fontSize: "12px",
                    fontWeight: "500",
                    marginTop: "0px"
                }}
                htmlFor={name}
            >
                {t(description)}
            </label>) : "" }
        </>
    );
}
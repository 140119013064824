import { Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";

export const WebTab = ({ darkMode, tabValue, handleChange }) => {
  const { t } = useTranslation();

  return (
    <Box bgcolor={`${darkMode ? "#171c26" : "#ffffff"}`} borderRadius="10px">
      <Tabs
        textColor="secondary"
        indicatorColor="secondary"
        value={tabValue}
        onChange={handleChange}
      >
        <Tab
          sx={{ color: "gray", ml: 5, textTransform: "capitalize" }}
          label={t("RECENT_BID")}
        />
        <Tab
          sx={{ color: "gray", ml: 12, textTransform: "capitalize" }}
          label={t("HISTORY")}
        />
      </Tabs>
    </Box>
  );
};

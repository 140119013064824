import {
  TextField,
  MenuItem,
} from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from "react-i18next";

const SelectComponent = ({attr, data, defaultValue}) => {

  const {
    name,
    label,
    isRequired,
    helperText,
    color,
    handleChange,
    index
  } = attr;
  useEffect(() => {
    if (index) {
      handleChange(data[index]);
    } else {
      handleChange(data[0]);
    }
  }, []);

  const handleChanged = (e) => {
    const {value} = e.target;
    handleChange(data[value]);
  }

  const { t } = useTranslation();

  return (
    <>
      <TextField
        name={name}
        select
        label={t(label)}
        helperText={t(helperText)}
        fullWidth
        required={isRequired}
        color={color ? color : 'secondary'}
        defaultValue={!data || data?.length === 0 ? '' : index ? index : 0}
        onChange = {handleChanged}
        sx={{mt: 3}}
        >
        {data ? data.map((option, index) => (
            <MenuItem key={option.id} value={index}>
            {option.name}
            </MenuItem>
        )) : null}
      </TextField>
    </>
  );
};

export default SelectComponent;
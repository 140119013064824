import {
  createWalletClient,
  createPublicClient,
  custom,
  toHex,
  keccak256,
  verifyMessage,
  parseEther,
  getContractAddress,
  toBytes,
  BaseError,
  ContractFunctionRevertedError,
} from "viem";

import { getNetwork, fetchTransaction, signMessage } from "@wagmi/core";

import {
  binanceContract,
  ethereumContract,
  polygonContract,
} from "../configs/constant";

import { confirmCurrentNetwork, switchCurrentNetwork } from "./wallet";
import RewardNFT from "../configs/abi/RewardNFT.json";
import ExchangeNFT from "../configs/abi/ExchangeNFT.json";

const [RewardContract] = ["reward", "exchange"];

const createNewWalletClient = async () => {
  const { chain } = getNetwork();
  const [account] = await window.ethereum.request({
    method: "eth_requestAccounts",
  });
  const walletClient = createWalletClient({
    account,
    transport: custom(window.ethereum),
    chain: chain,
  });
  return walletClient;
};

const createNewPublicClient = async () => {
  const { chain } = getNetwork();
  const [account] = await window.ethereum.request({
    method: "eth_requestAccounts",
  });

  const publicClient = createPublicClient({
    account,
    transport: custom(window.ethereum),
    chain: chain,
  });

  return publicClient;
};

export const getSignatureOVerify = async ({
  message,
  chainId = "Polygon",
  verifyMsg = "",
}) => {
  console.log("here is getSignatureOverify 1");
  if (!confirmCurrentNetwork(chainId)) {
    await switchCurrentNetwork(chainId);
  }
  console.log("here is getSignatureOverify 2");

  const [account] = await window.ethereum.request({
    method: "eth_requestAccounts",
  });
  const minMessageHash = keccak256(toHex(message));
  const minMessageHashBytes = toBytes(minMessageHash);
  const signature = await signMessage({
    message: { raw: minMessageHashBytes },
  });
  console.log("here is getSignatureOverify 3");

  return { wallet: account, signature: signature };
};

export const createContractWrite = async (
  contractConfig,
  functionName,
  args
) => {
  const publicClient = await createNewPublicClient();
  const walletClient = await createNewWalletClient();
  const [account] = await window.ethereum.request({
    method: "eth_requestAccounts",
  });

  try {
    const { request } = await publicClient.simulateContract({
      ...contractConfig,
      functionName: functionName,
      account: account,
      args: [...args],
    });
    const hash = await walletClient.writeContract(request);
    return hash;
  } catch (err) {
    console.log(err);
    if (err instanceof BaseError) {
      const revertError = err.walk(
        (err) => err instanceof ContractFunctionRevertedError
      );
      if (revertError instanceof ContractFunctionRevertedError) {
        const errorName = revertError.data?.errorName ?? "";
        // do something with `errorName`
      }
    }

    return false;
  }
};

export const getContractInfo = (chain, address = "", isReward = false) => {
  return {
    address:
      address !== ""
        ? address
        : chain === "Binance"
        ? binanceContract
        : chain === "Polygon"
        ? polygonContract
        : ethereumContract,
    abi: isReward ? RewardNFT.abi : ExchangeNFT.abi,
  };
};

export const getExchangeRewardAddress = (chain = "Polygon") => {
  return chain === "Binance"
    ? binanceContract
    : chain === "Polygon"
    ? polygonContract
    : ethereumContract;
};

const getAbi = (abiType = RewardContract) => {
  return abiType === RewardContract ? RewardNFT : ExchangeNFT;
};

export const createContractConfig = ({ address, chain, type, isDeploy }) => {
  address =
    address && type === RewardContract
      ? address
      : getExchangeRewardAddress(chain);
  const abi = getAbi(type);

  if (isDeploy) {
    return abi;
  }

  return {
    address: address,
    abi: abi.abi,
  };
};

export const deployNewContract = async ({ abi, args }) => {
  const walletClient = await createNewWalletClient();

  if (!abi.abi) {
    abi = abi === "reward" ? RewardNFT : ExchangeNFT;
  }

  const hash = await walletClient.deployContract({
    ...abi,
    args: [...args],
  });

  const [account] = await walletClient.getAddresses();

  const transaction = await fetchTransaction({
    hash: hash,
  });

  return await getContractAddress({
    from: account,
    nonce: transaction.nonce,
  });
};

export const getContractSignature = async (submitData, tokenInfo) => {
  const nonce = "1";
  console.log(window.ethereum);
  const [account] = await window?.ethereum.request({
    method: "eth_requestAccounts",
  });
  console.log("Here is getContractSignature 1", account);
  let signMessage = nonce.concat(
    submitData.uid,
    submitData.auction_start_at.toString(),
    submitData?.contract
      ? submitData?.contract?.toLowerCase()
      : tokenInfo?.collection.contract?.toLowerCase(),
    parseEther(submitData?.price?.toString()).toString(),
    submitData?.token_id.toString(),
    tokenInfo.collection.creator_wallet_address.toLowerCase(),
    account.toLowerCase(),
    tokenInfo.blockchain === "Binance"
      ? binanceContract.toLowerCase()
      : tokenInfo.blockchain === "Polygon"
      ? polygonContract.toLowerCase()
      : ethereumContract.toLowerCase()
  );

  return await getSignatureOVerify({
    message: signMessage,
    chainId: tokenInfo.blockchain,
  });
};

export const checkVerifyMessage = async (
  submitData,
  blockchain,
  collection,
  colAddress,
  walletAddress
) => {
  const nonce = "1";
  let signMessage = nonce.concat(
    submitData.uid,
    submitData.auctionEndAt.toString(),
    colAddress
      ? submitData?.contract?.toLowerCase()
      : collection?.contract?.toLowerCase(),
    parseEther(submitData?.price?.toString()).toString(),
    submitData?.token_id.toString(),
    // submitData?.creator.toLowerCase(),
    walletAddress.toLowerCase(),
    blockchain === "Binance"
      ? binanceContract.toLowerCase()
      : blockchain === "Polygon"
      ? polygonContract.toLowerCase()
      : ethereumContract.toLowerCase()
  );
  return await verifyMessage({
    address: walletAddress,
    message: signMessage,
    signature: submitData?.signature,
  });
};

export const getSignaturePlain = ({ plainData, blockchain }) => {
  const nonce = "1";
  let signPlainData = [...plainData];
  if (blockchain) {
    const contract =
      blockchain === "Binance"
        ? binanceContract.toLowerCase()
        : blockchain === "Polygon"
        ? polygonContract.toLowerCase()
        : ethereumContract.toLowerCase();
    signPlainData = [nonce, ...signPlainData, contract];
  }

  return signPlainData.join("");
};

import React, { useEffect, useState, lazy, Suspense, memo } from "react";
import { Box, Grid, Stack, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import useQuery from "hooks/useQuery";
import { Chains, sort, order, defaultFilterValues } from "configs/constant";
const NftCardContainer = lazy(() =>
  import("components/NftCard/NftCardContainer/NftCardContainer")
);
const CategoryTab = lazy(() => import("components/CategoryTab/CategoryTab"));
const SelectComponent = lazy(() =>
  import("components/Auction/SelectComponent")
);
const MobileSelectComponent = lazy(() =>
  import("components/Auction/MobileSelectComponent")
);
const SearchInput = lazy(() => import("components/Auction/SearchInput"));

const Explore = ({ darkMode }) => {
  const { t } = useTranslation();
  const query = useQuery();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [blockchain, setBlockchain] = useState(
    defaultFilterValues?.blockchain?.value
  );
  const [orderData, setOrderData] = useState(
    order[defaultFilterValues?.sort?.value]
  );
  const [sortOptions, setSorOptions] = useState(
    defaultFilterValues?.sort?.display
  );
  const [orderOptions, setOrderOptions] = useState(
    defaultFilterValues?.order?.display
  );
  const [stateChanged, setStateChanged] = useState(true);
  const [chainAnchorEl, setChainAnchorEl] = useState(null);
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const [isSearch, setIsSearch] = useState(false);
  const [orderAnchorEl, setOrderAnchorEl] = useState(null);
  const [currentTab, setCurrentTab] = useState(
    query.get("type") ? query.get("type") : "all"
  );
  const [searchText, setSearchText] = useState("");
  const [sortBy, setSortBy] = useState(defaultFilterValues?.sort?.value);
  const [orderBy, setOrderBy] = useState(defaultFilterValues?.order?.value);

  const openChainMenu = Boolean(chainAnchorEl);
  const openSortMenu = Boolean(sortAnchorEl);
  const openOrderMenu = Boolean(orderAnchorEl);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /* chain filter handler */
  const handleOpenChainMenu = (event) => {
    setIsSearch(false);
    setChainAnchorEl(event.currentTarget);
  };

  const handleCloseChainMenu = () => {
    setIsSearch(false);
    setChainAnchorEl(null);
  };

  const handleChainBtnClicked = (item) => {
    setIsSearch(false);
    setBlockchain(item?.value);
    handleCloseChainMenu();
  };

  /* sort filter handler */
  const handleOpenSortMenu = (event) => {
    setSortAnchorEl(event.currentTarget);
  };

  const handleCloseSortMenu = () => {
    setSortAnchorEl(null);
  };

  const handleSortBtnClicked = (item) => {
    const firstOrderDatum = order[item.value]?.length
      ? order[item.value][0]
      : {};
    setIsSearch(false);
    setSorOptions(t(item.display));
    setOrderData(order[item.value]);
    setOrderOptions(firstOrderDatum?.display);
    setOrderBy(firstOrderDatum?.value);
    setSortBy(item.value);
    setStateChanged(true);
    handleCloseSortMenu();
  };

  /* order filter handlers */
  const handleOpenOrderMenu = (event) => {
    setIsSearch(false);
    setOrderAnchorEl(event.currentTarget);
  };

  const handleCloseOrderMenu = () => {
    setOrderAnchorEl(null);
  };

  const handleOrderBtnClicked = (item) => {
    setOrderOptions(t(item.display));
    setStateChanged(true);
    setIsSearch(false);
    setOrderBy(item.value);
    handleCloseOrderMenu();
  };
  const handleClickFilterTab = (path) => {
    setStateChanged(true);
    setIsSearch(false);
    setCurrentTab(path);
  };

  const handleChangeSearch = (e) => {
    const { value } = e.target;
    setIsSearch(true);
    setSearchText(value);
  };

  return (
    <div
      style={{
        paddingLeft: isMobile ? "24px" : "144px",
        paddingRight: isMobile ? "24px" : "144px",
      }}
    >
      {isMobile && (
        <Box>
          <Box
            color={darkMode ? "#ffffff" : "#121212"}
            mt={!isMobile ? 11.5 : 2}
            mb={3}
          >
            {!isMobile ? (
              <Stack direction="row" alignItems="center" gap={3}>
                <Box width="35%">
                  <Suspense fallback={<div></div>}>
                    <SearchInput
                      darkMode={darkMode}
                      handleChangeSearch={handleChangeSearch}
                      isMobile={false}
                    />
                  </Suspense>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Suspense fallback={<div></div>}>
                      <SelectComponent
                        handleBtnOnClick={handleOpenChainMenu}
                        handleMenuOnClick={handleChainBtnClicked}
                        handleOnClose={handleCloseChainMenu}
                        label={"BLOCKCHAIN"}
                        open={openChainMenu}
                        currentValue={blockchain}
                        anchorEl={chainAnchorEl}
                        data={Chains}
                      />
                    </Suspense>
                  </Grid>
                  <Grid item xs={4}>
                    <Suspense fallback={<div></div>}>
                      <SelectComponent
                        handleBtnOnClick={handleOpenSortMenu}
                        handleMenuOnClick={handleSortBtnClicked}
                        handleOnClose={handleCloseSortMenu}
                        label={"SORT_BY"}
                        open={openSortMenu}
                        currentValue={sortOptions}
                        anchorEl={sortAnchorEl}
                        data={sort}
                      />
                    </Suspense>
                  </Grid>
                  <Grid item xs={4}>
                    <Suspense fallback={<div></div>}>
                      <SelectComponent
                        handleBtnOnClick={handleOpenOrderMenu}
                        handleMenuOnClick={handleOrderBtnClicked}
                        handleOnClose={handleCloseOrderMenu}
                        label={"ORDER_BY"}
                        open={openOrderMenu}
                        currentValue={orderOptions}
                        anchorEl={orderAnchorEl}
                        data={orderData}
                      />
                    </Suspense>
                  </Grid>
                </Grid>
              </Stack>
            ) : (
              <Stack direction="row" alignItems="center" gap={3}>
                <Suspense fallback={<div></div>}>
                  <SearchInput
                    darkMode={darkMode}
                    handleChangeSearch={handleChangeSearch}
                    isMobile={true}
                  />
                </Suspense>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Suspense fallback={<div></div>}>
                      <MobileSelectComponent
                        handleBtnOnClick={handleOpenChainMenu}
                        handleMenuOnClick={handleChainBtnClicked}
                        handleOnClose={handleCloseChainMenu}
                        open={openChainMenu}
                        anchorEl={chainAnchorEl}
                        data={Chains}
                      />
                    </Suspense>
                  </Grid>
                  <Grid item xs={4}>
                    <Suspense fallback={<div></div>}>
                      <MobileSelectComponent
                        handleBtnOnClick={handleOpenSortMenu}
                        handleMenuOnClick={handleSortBtnClicked}
                        handleOnClose={handleCloseSortMenu}
                        open={openSortMenu}
                        anchorEl={sortAnchorEl}
                        data={sort}
                      />
                    </Suspense>
                  </Grid>
                  <Grid item xs={4}>
                    <Suspense fallback={<div></div>}>
                      <MobileSelectComponent
                        handleBtnOnClick={handleOpenOrderMenu}
                        handleMenuOnClick={handleOrderBtnClicked}
                        handleOnClose={handleCloseOrderMenu}
                        open={openOrderMenu}
                        anchorEl={orderAnchorEl}
                        data={orderData}
                      />
                    </Suspense>
                  </Grid>
                </Grid>
              </Stack>
            )}
          </Box>
          <Suspense fallback={<div></div>}>
            <CategoryTab
              darkMode={darkMode}
              currentAuctionTab={currentTab}
              handleClickFilterTab={handleClickFilterTab}
            />
          </Suspense>
        </Box>
      )}

      <Suspense fallback={<div></div>}>
        <NftCardContainer
          darkMode={darkMode}
          isSearch={isSearch}
          currentTab={currentTab}
          searchText={searchText}
          stateChanged={stateChanged}
          handeStateChanged={setStateChanged}
          blockchain={blockchain}
          sortBy={sortBy}
          orderBy={orderBy}
        />
      </Suspense>
    </div>
  );
};
const areEqualProp = (prevProps, nextProps) => {
  return prevProps.darkMode === nextProps.darkMode;
};
export default memo(Explore, areEqualProp);

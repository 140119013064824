import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const GradientButtonPrimary = styled(Button)({
  color: "#ffffff",
  border: "none",
  background: "linear-gradient(91.93deg, #000000 1.75%, #000000 98.27%)",
  borderRadius: "10px",
  boxShadow: "none",
  padding: "8px 1.5rem",
  zIndex: "1000",
  textTransform: "capitalize",
  "&:hover": {
    boxShadow: "none",
    background: "linear-gradient(91.93deg, #333333 98.27%, #333333 1.75%)",
  },
  "&:active": {
    boxShadow: "none",
    background: "linear-gradient(91.93deg, #333333 98.27%, #333333 1.75%)",
  },
  "&:focus": {
    background: "linear-gradient(91.93deg, #333333 98.27%, #333333 1.75%)",
  },
});
const GradientButtonSecondary = styled(Button)({
  color: "#000000",
  border: "none",
  background: "linear-gradient(91.95deg, #ffffff 1.75%, #ffffff 98.13%)",
  borderRadius: "10px",
  boxShadow: "none",
  padding: "8px 1.5rem",
  textTransform: "capitalize",
  "&:hover": {
    boxShadow: "none",
    background: "linear-gradient(91.95deg, #ffffff 98.13%, #ffffff 1.75%)",
  },
  "&:active": {
    boxShadow: "none",
    background: "linear-gradient(91.95deg, #ffffff 98.13%, #ffffff 1.75%)",
  },
  "&:focus": {
    background: "linear-gradient(91.95deg, #ffffff 98.13%, #ffffff 1.75%)",
  },
});

export { GradientButtonPrimary, GradientButtonSecondary };

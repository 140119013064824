import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useApolloQuery } from 'hooks/useApolloQuery';
import { useMount } from 'hooks/useMount';
import { useTranslation } from 'react-i18next';
import { AuctionStatus, SellStatus } from 'configs/constant';

function createData(created_at, nft, sell_status, price) {
    return { created_at, nft, sell_status, price };
}

const Activity = () => {
    const { t } = useTranslation();
    const columns = [
        { id: 'created_at', label: t('CREATED_AT'), minWidth: 170 },
        { id: 'nft', label: t('NFT'), minWidth: 100 },
        {
            id: 'sell_status',
            label: t('OPERATE_TYPE'),
            minWidth: 170,
            align: 'right',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'price',
            label: t('PRICE'),
            minWidth: 170,
            align: 'right',
            format: (value) => value.toLocaleString('en-US'),
        },
    ];

    let rows = [];
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    let params = {
        email: localStorage.getItem('email'),
        page: page,
        limit: rowsPerPage
    };
    const [userHistory, setUserHistory] = useState({});
    const [historyRows, setHistoryRows] = useState([]);
    const { data,  refetch } = useApolloQuery('getUserHistory', params);
    useMount(refetch);
    useEffect(() => {
        if (data) {
            setUserHistory(data.getUserHistory);
        }
    }, [data]);

    useEffect(() => {
        rows = [];
        if (userHistory.histories) {
            userHistory?.histories.map((hs) => {
                const status = hs?.sell_status === SellStatus.ON_SELL ? AuctionStatus?.LISTING : hs?.sell_status;
                rows.push(createData(hs?.created_at, hs?.nft?.name, status, hs?.price));
            });

            setHistoryRows(rows);
        }
    }, [userHistory])
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        refetch();
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        refetch();
    };

    return (
        <>
            <>
            <Grid>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                            {columns.map((column, index) => (
                                <TableCell
                                    key={index}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                >
                                {column.label}
                                </TableCell>
                            ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {historyRows
                            ?.map((historyRow, index) => {
                                return (
                                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                    {columns.map((column) => {
                                    const value = historyRow[column.id];
                                    return (
                                        <TableCell key={column.id} align={column.align}>
                                        {column.format && typeof value === 'number'
                                            ? column.format(value)
                                            : value}
                                        </TableCell>
                                    );
                                    })}
                                </TableRow>
                                );
                            })}
                        </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        component="div"
                        count={userHistory?.totalCount ? userHistory?.totalCount : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Grid>
            </>
        </>
    );
};

export default Activity;

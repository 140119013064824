import { useState } from "react";
import { Box } from "@mui/system";
import { Typography, Modal, Backdrop, Fade, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { HiTemplate } from "react-icons/hi";
import { chainIcons } from "configs/constant";
import { getDateTimeDifference } from "Utils";
import styles from "components/NftCard/NftCardDetails/NftWork.module.css";

export const MobileMetaInfo = ({
  darkMode,
  auctionStartDate,
  auctionEndDate,
  metaInfo,
}) => {
  const { name, collection_name, description, last_price, blockchain } =
    metaInfo;
  const { t } = useTranslation();

  const [openModal, setOpenModal] = useState(false);
  const dateRange = getDateTimeDifference(
    auctionStartDate,
    auctionEndDate
  ).days;
  const handleCloseModal = () => setOpenModal(false);
  return (
    <>
      <Modal
        sx={{ zIndex: 500000 }}
        open={openModal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Box
            bgcolor={darkMode ? "#171c26" : "#ffffff"}
            className={styles.modalStyleWeb}
          >
            <Typography
              className={styles.itemDetailsModalTitle}
              color="secondary"
              variant="h6"
              component="div"
            >
              <Typography component="span" color="secondary" sx={{ mt: 0.3 }}>
                <HiTemplate fontSize={"1.5rem"} />
              </Typography>
              <Typography variant="h6" component="span" mt={-0.2}>
                {t("ITEM_DETAILS")}
              </Typography>
            </Typography>
            <Typography
              className={styles.readMoreModalText}
              variant="body2"
              component="p"
              color="secondary"
              lineHeight={2}
              height={250}
              pr={2.5}
            >
              {description}
            </Typography>
          </Box>
        </Fade>
      </Modal>
      <Box>
        <Typography
          variant="subtitle2"
          component="h2"
          color="secondary.main"
          mb={2}
        >
          {name} ({collection_name})
        </Typography>
        <Typography
          variant="caption"
          component="div"
          lineHeight={1.8}
          mb={2}
          color="secondary.main"
          textAlign="left"
          fontSize={15}
        >
          {auctionStartDate &&
            `${t("SALE_ENDS")} ${auctionStartDate?.toDateString()} ${
              dateRange >= 1 ? "" : auctionStartDate?.toLocaleTimeString()
            }  to ${
              dateRange >= 1
                ? auctionEndDate?.toDateString()
                : auctionEndDate?.toLocaleTimeString()
            }`}
        </Typography>

        <>
          <Typography
            variant="subtitle2"
            component="p"
            color={darkMode ? "#FFFFFF" : "#121212"}
            mb={1}
          >
            {t("PRICE")}
          </Typography>
          <Stack
            variant="h5"
            component="div"
            color="secondary.main"
            mb={3}
            fontWeight={500}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <img
              src={chainIcons[blockchain]?.logo}
              alt="Polygon Icon"
              mb={-0.5}
              width="20"
              height={"20"}
            />
            <Typography
              variant="body1"
              component="span"
              fontSize={"20px"}
              marginLeft={"10px"}
            >
              {" "}
              {" $"}
              {`${last_price}`}
            </Typography>
          </Stack>
        </>
      </Box>
    </>
  );
};

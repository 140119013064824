import { useState } from "react";
import { Box } from "@mui/system";
import { Skeleton } from "@mui/material";
import styles from "components/NftCard/NftCardDetails/NftWork.module.css";

export const NftImage = ({ children, darkMode, isMobile, tokenURI, name }) => {
  const [loading, setLoading] = useState(true);
  return (
    <>
      {!isMobile ? (
        <Box
          sx={{
            position: "relative",
          }}
        >
          <img
            className={styles.nftDisplayImage}
            src={tokenURI}
            alt={name}
            onLoad={() => setLoading(false)}
            hidden={loading}
            style={{
              display: loading ? "None" : "block",
            }}
            loading="eager"
          />
          {loading ? (
            <Skeleton
              sx={{
                backgroundColor: `${darkMode ? "#171C26" : "#ffffff"}`,
              }}
              animation="wave"
              variant="rectangular"
              style={{
                width: "100%",
                height: "400px",
                borderRadius: "20px",
              }}
            />
          ) : null}
          {children}
        </Box>
      ) : (
        <Box zIndex={10}>
          <img
            className={styles.nftDisplayImageMobile}
            src={tokenURI}
            alt={name}
            onLoad={() => setLoading(false)}
            hidden={loading}
            style={{
              display: loading ? "None" : "block",
            }}
            loading="eager"
          />
          {loading ? (
            <Skeleton
              sx={{
                backgroundColor: `${darkMode ? "#171C26" : "#ffffff"}`,
              }}
              animation="wave"
              variant="rectangular"
              style={{
                width: "100%",
                height: "400px",
                borderRadius: "20px",
              }}
            />
          ) : null}
          {children}
        </Box>
      )}
    </>
  );
};

import React from "react";
import ReactDOM from "react-dom";

import { wagmiConfig, chains } from "services/web3/wagmiConfig";
import { WagmiConfig } from "wagmi";
import { RainbowKitProvider, lightTheme } from "@rainbow-me/rainbowkit";
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloProvider,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import merge from "lodash.merge";

import "index.css";
import App from "App";
import { Provider } from "react-redux";
import Cookies from "js-cookie";
import { store } from "store";
import reportWebVitals from "reportWebVitals";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { backend_graphql_url } from "configs/constant";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["en", "fr", "pt", "ge"],
    fallbackLng: "en",
    detection: {
      order: ["cookie", "htmlTag", "localStorage", "path"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "/locales/{{lng}}/translation.json",
    },
    react: {
      useSuspense: false,
    },
  });

const httpLink = createHttpLink({
  uri: backend_graphql_url,
});

const authLink = setContext((_, { headers }) => {
  const accessToken = Cookies.get("access_token");
  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : "",
    },
  };
});

const myTheme = merge(lightTheme(), {
  colors: {
    connectButtonBackground: "black",
    generalBorder: "none",
    connectButtonText: "white",
    // downloadTopCardBackground: "linear-gradient(91.93deg, #AD18C7 1.75%, #E552FF 98.27%)",
    // modalBackground: ""
  },
});

const cache = new InMemoryCache();
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider chains={chains} theme={myTheme}>
          <Provider store={store}>
            <App />
          </Provider>
        </RainbowKitProvider>
      </WagmiConfig>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();

import React, { useEffect, useState, lazy, Suspense } from "react";
import { Typography, Avatar, Grid, useMediaQuery, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";

import BackgroundWrinkles1 from "assets/BackgroundWrinkles1.svg";
import BackgroundWrinklesLight from "assets/backgroundWrinklesLight.svg";
import { useTheme } from "@emotion/react";
import useQuery from "hooks/useQuery";
import CreateCollection from "components/CreateCollection/CreateCollection";
import Loader from "components/Loader/Loader";
import TabPanel from "./TabPanel";
import TabList from "./TabList";
import StyledMobileTab from "./StyledMobileTab";
import Activity from "./Activity";
import { defaultPngAvatar } from "configs/constant";
import { useFetchData } from "hooks/useFetchData";
import { useCheckedLogin } from "hooks/useCheckedLogin";
import { useApolloQuery } from "hooks/useApolloQuery";
import { useMount } from "hooks/useMount";
const NftCardLists = lazy(() =>
  import("components/NftCard/NftCardContainer/NftCardLists")
);

const DummyUserProfile = ({ darkMode, userId }) => {
  const query = useQuery();
  let navigate = useNavigate();
  const [sellerData, setSellerData] = useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [isAdmin, setIsAdmin] = useState(0);
  const [tabValue, setTabValue] = useState(
    parseInt(!query.get("tab") ? 0 : query.get("tab"))
  ); // setting tab value for changing
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isLoading, setIsLoading] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState("");
  const dataArea = userId ? "userInfoById" : "userInfo";
  const params = userId ? { user_id: parseInt(userId) } : {};

  // const {data: userData, isLoaded: userloaded} = useFetchData(dataArea, '', params);
  const { data: userData, refetch } = useApolloQuery(dataArea, params);
  useEffect(() => {
    if (userData) {
      setSellerData(userData[dataArea]);
      setFilterStatement((prevState) => ({
        ...prevState,
        email: userData[dataArea]?.email,
      }));
    }
  }, [userData]);

  useMount(refetch);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setLoaderMessage("Loading...");
      setIsLoading(false);
    };
    fetchData();
  }, []);

  useCheckedLogin(!userId);
  // Tab handler
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    refetch();
    navigate(`?tab=${newValue}`);
  };
  const handleCloseModal = () => setOpenModal(false);

  // This will be deleted when the API endpoint added
  const [filterStatement, setFilterStatement] = useState({
    status: 0,
    collections: [],
    chainNet: "",
    email: sellerData?.email,
  });
  useEffect(() => {
    if (sellerData?.role === "creator" || sellerData?.role === "admin")
      setIsAdmin(1);
  }, [sellerData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box
      sx={{
        mt: 11,
      }}
    >
      <Loader
        isLoading={isLoading}
        message={loaderMessage}
        darkMode={darkMode}
      />
      {isMobile && (
        <Box
          sx={{
            position: "fixed",
            top: "0%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "10000",
            mt: 5,
          }}
        >
          <Typography
            variant="subtitle1"
            color="secondary"
            component="div"
            sx={{
              borderBottom: `${
                darkMode ? "2px solid #ffffff" : "1px solid #171c26"
              }`,
            }}
          >
            {"My Profile"}
          </Typography>
        </Box>
      )}
      <Box
        style={{
          position: "fixed",
          height: "100vh",
        }}
      >
        <img
          src={darkMode ? BackgroundWrinkles1 : BackgroundWrinklesLight}
          alt="Wrinkles"
        />
      </Box>
      {!isMobile ? (
        <Box zIndex={100} color={darkMode ? "#ffffff" : "#121212"}>
          <Box
            bgcolor={darkMode ? "#121212" : "#ffffff"}
            sx={{
              borderRadius: "10px",
            }}
          >
            <Box
              sx={{
                backgroundImage: `linear-gradient(180deg, rgba(4, 4, 4, 0) 0%, ${
                  darkMode ? "#040404" : "#e3dcdc"
                } 100%), url(${sellerData?.cover})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                p: 5,
                borderRadius: "10px",
              }}
            >
              <Box>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Avatar
                    src={
                      sellerData?.image ? sellerData?.image : defaultPngAvatar
                    }
                    alt={sellerData?.username}
                    sx={{ border: "2px solid #fff", width: 120, height: 120 }}
                  />
                </Box>
                <Stack
                  mt={2}
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    gap={1}
                  >
                    <Typography color="secondary" variant="h5">
                      {sellerData?.email}
                    </Typography>
                    {sellerData?.username ? (
                      <Typography color="secondary" variant="h5">
                        ({sellerData?.username})
                      </Typography>
                    ) : null}
                  </Stack>
                  <Typography
                    color="secondary"
                    mt={1}
                    variant="body2"
                    textAlign="center"
                    lineHeight={2}
                  >
                    {" "}
                    {sellerData?.bio}
                  </Typography>
                </Stack>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box
              bgcolor={darkMode ? "#171C26" : "#ffffff"}
              sx={{
                borderRadius: "10px",
                mb: 4,
              }}
            >
              <TabList
                tabValue={tabValue}
                isAdmin={isAdmin}
                handleChange={handleChange}
              />
            </Box>
            <Box sx={{ mx: "144px", mt: -2 }}>
              {isAdmin && (
                <TabPanel value={tabValue} index={0}>
                  <Grid container spacing={0.5} justifyContent="flex-start">
                    <Grid item xs={12} justifyContent="flex-start">
                      {tabValue === 0 && userData && (
                        <Suspense fallback={<></>}>
                          <NftCardLists
                            darkMode={darkMode}
                            filterStatement={filterStatement}
                            queryTab={"created"}
                            isAuction={false}
                            dataField={"auctionWinner"}
                          />
                        </Suspense>
                      )}
                    </Grid>
                  </Grid>
                </TabPanel>
              )}
              <TabPanel value={tabValue} index={isAdmin}>
                {tabValue === isAdmin && filterStatement?.email && (
                  <Suspense fallback={<></>}>
                    <NftCardLists
                      darkMode={darkMode}
                      filterStatement={filterStatement}
                      queryTab={"owned"}
                      isAuction={false}
                      dataField={"owner"}
                    />
                  </Suspense>
                )}
              </TabPanel>
              <TabPanel value={tabValue} index={isAdmin + 1}>
                <Grid container spacing={0.5} justifyContent="flex-start">
                  <Grid item xs={12} justifyContent="flex-start">
                    {tabValue === isAdmin + 1 && filterStatement?.email && (
                      <Suspense fallback={<></>}>
                        <NftCardLists
                          darkMode={darkMode}
                          filterStatement={filterStatement}
                          queryTab={"auctionWined"}
                          isAuction={true}
                          dataField={"auctionWinner"}
                        />
                      </Suspense>
                    )}
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={isAdmin + 2}>
                <Grid container spacing={0.5} justifyContent="flex-start">
                  <Grid item xs={12} justifyContent="flex-start">
                    {tabValue === isAdmin + 2 && filterStatement?.email && (
                      <Suspense fallback={<></>}>
                        <NftCardLists
                          darkMode={darkMode}
                          filterStatement={filterStatement}
                          queryTab={"claimed"}
                          isAuction={true}
                          dataField={"owner"}
                        />
                      </Suspense>
                    )}
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={isAdmin + 3}>
                <Grid container spacing={0.5} justifyContent="flex-start">
                  <Grid item xs={12} justifyContent="flex-start">
                    {tabValue === isAdmin + 3 && filterStatement?.email && (
                      <Suspense fallback={<></>}>
                        <NftCardLists
                          darkMode={darkMode}
                          filterStatement={filterStatement}
                          queryTab={"blockchainOwned"}
                          isAuction={false}
                          dataField={"blockchainOwner"}
                        />
                      </Suspense>
                    )}
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={isAdmin + 4}>
                {tabValue === isAdmin + 4 && filterStatement?.email && (
                  <Activity></Activity>
                )}
              </TabPanel>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{ position: "relative", overflowX: "hidden", mt: -10 }}
          color={darkMode ? "#ffffff" : "#121212"}
        >
          <Box
            bgcolor={darkMode ? "#121212" : "#ffffff"}
            sx={{
              borderRadius: "20px",
              p: 1,
            }}
          ></Box>
          <Box>
            <Box
              sx={{
                borderRadius: "10px",
                mt: 3,
                mb: 4,
                mx: 2,
              }}
            >
              <StyledMobileTab
                darkMode={darkMode}
                isAdmin={isAdmin}
                tabValue={tabValue}
                handleChange={handleChange}
              />
            </Box>
            <Box sx={{ marginLeft: -6 }}>
              {isAdmin && (
                <TabPanel value={tabValue} index={0}>
                  <Grid container spacing={0.5} justifyContent="flex-start">
                    <Grid item xs={12} justifyContent="flex-start">
                      {filterStatement?.email && (
                        <Suspense fallback={<></>}>
                          <NftCardLists
                            darkMode={darkMode}
                            filterStatement={filterStatement}
                            queryTab={"created"}
                            isAuction={false}
                            dataField={"creator"}
                          />
                        </Suspense>
                      )}
                    </Grid>
                  </Grid>
                </TabPanel>
              )}
              <TabPanel value={tabValue} index={isAdmin}>
                <Grid container spacing={0.5} justifyContent="flex-start">
                  <Grid item xs={12} justifyContent="flex-start">
                    {filterStatement?.email && (
                      <Suspense fallback={<></>}>
                        <NftCardLists
                          darkMode={darkMode}
                          filterStatement={filterStatement}
                          queryTab={"owned"}
                          isAuction={false}
                          dataField={"owner"}
                        />
                      </Suspense>
                    )}
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={isAdmin + 1}>
                <Grid container spacing={0.5} justifyContent="flex-start">
                  <Grid item xs={12} justifyContent="flex-start">
                    {filterStatement?.email && (
                      <Suspense fallback={<></>}>
                        <NftCardLists
                          darkMode={darkMode}
                          filterStatement={filterStatement}
                          queryTab={"auctionWined"}
                          isAuction={true}
                          dataField={"auctionWinner"}
                        />
                      </Suspense>
                    )}
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={isAdmin + 2}>
                <Grid container spacing={0.5} justifyContent="flex-start">
                  <Grid item xs={12} justifyContent="flex-start">
                    {filterStatement?.email && (
                      <Suspense fallback={<></>}>
                        <NftCardLists
                          darkMode={darkMode}
                          filterStatement={filterStatement}
                          queryTab={"claimed"}
                          isAuction={false}
                          dataField={"owner"}
                        />
                      </Suspense>
                    )}
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={isAdmin + 3}>
                <Grid container spacing={0.5} justifyContent="flex-start">
                  <Grid item xs={12} justifyContent="flex-start">
                    {tabValue === isAdmin + 3 && filterStatement?.email && (
                      <Suspense fallback={<></>}>
                        <NftCardLists
                          darkMode={darkMode}
                          filterStatement={filterStatement}
                          queryTab={"blockchainOwned"}
                          isAuction={false}
                          dataField={"blockchainOwner"}
                        />
                      </Suspense>
                    )}
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={tabValue} index={isAdmin + 4}>
                {filterStatement?.email && <Activity></Activity>}
              </TabPanel>
            </Box>
          </Box>
        </Box>
      )}
      <CreateCollection
        open={openModal}
        handleClose={handleCloseModal}
        darkMode={darkMode}
      />
    </Box>
  );
};

export default DummyUserProfile;

import { Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { useTheme } from "@emotion/react";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
// Icons
import { MdAddToPhotos } from "react-icons/md";
import { useAccount } from "wagmi";
import Loader from "components/Loader/Loader";
import { GradientButtonPrimary } from "Utils/GradientButtons/GradientButtons";
import UploadComponent from "./UploadComponent";
import CollectionSelect from "./CollectionSelect";
import InputComponent from "./InputComponent";
import TextAreaExtend from "./TextAreaExtend";
import {
  pinFileToIPFS,
  pinJsonToIPFS,
  confirmCurrentNetwork,
  switchCurrentNetwork,
} from "Utils";
import { PINATA_ASSET_BASE_URL } from "configs/constant";
import AlertDialog from "components/Alert/AlertDialog";
import SwitchNetworkDialog from "components/Alert/SwitchNetworkDialog";
import { useApolloMutation } from "hooks/useApolloQuery";
import { useCheckedLogin } from "hooks/useCheckedLogin";
import { checkEnable } from "services/api.slice";

const CreateAssets = ({ darkMode }) => {
  const { openConnectModal } = useConnectModal();
  const loginedUser = useSelector((state) => state.auth.user);
  const { handleMutation } = useApolloMutation("createNfts");
  let navigate = useNavigate();
  useCheckedLogin();

  useEffect(async () => {
    const userStatus = await checkEnable();
    if (loginedUser?.role !== "creator") {
      if (!userStatus.status) {
        navigate("/home");
      }
    }
  }, [loginedUser, navigate]);

  const [image, setImage] = useState(null);
  const { t } = useTranslation();
  const [chainNet, setChainNet] = useState("Polygon");
  const { enqueueSnackbar } = useSnackbar();
  const [isCreatingNft, setIsCreatingNft] = useState(false);
  const [switchNetworkModalOpen, setSwitchNetworkModalOpen] = useState(false);
  const [nftItem, setNftItem] = useState();
  const [loadMsg, setLoadMsg] = useState("loading");
  const [isSubmit, setIsSubmit] = useState(false);
  const { address } = useAccount();
  const { isConnected } = useAccount();

  let postData = {};

  const handleChanged = (e) => {
    const { name, value } = e.target;
    setNftItem((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const showSnackMessage = (msg, variant) => {
    enqueueSnackbar(msg, { variant });
  };

  const handleSelectChanged = (data) => {
    setNftItem((prevState) => ({
      ...prevState,
      collection_id: data ? parseInt(data["id"]) : 0,
    }));

    setNftItem((prevState) => ({
      ...prevState,
      category: data ? data["category"] : "",
    }));

    setNftItem((prevState) => ({
      ...prevState,
      collection_name: data ? data["name"] : "",
    }));

    setChainNet(data ? data["blockchain"] : "Polygon");

    setNftItem((prevState) => ({
      ...prevState,
      blockchain: chainNet,
    }));
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const onClickSwitchNetwork = async () => {
    try {
      await switchCurrentNetwork(chainNet);
      setSwitchNetworkModalOpen(false);
      setIsCreatingNft(true);
      handleCreateNft();
    } catch (e) {
      console.log(e);
    }
  };

  const handleSubmit = async (e) => {
    if (!loginedUser?.wallet_address) {
      showSnackMessage("Please add your wallet address at first", "error");
    } else if (
      loginedUser?.role === "creator" ||
      loginedUser?.role === "admin" ||
      loginedUser?.wallet_address
    ) {
      if (!isConnected) {
        openConnectModal();
      }
      e.preventDefault();
      e.stopPropagation();
      setIsSubmit(true);
    }
  };

  const handleAcceptSubmit = async () => {
    setIsSubmit(false);
    if (await confirmCurrentNetwork(chainNet)) {
      setIsCreatingNft(true);
      await handleCreateNft();
    } else {
      setSwitchNetworkModalOpen(true);
    }
  };

  const handleCreateNft = async () => {
    setLoadMsg("Uploading image to the IPFS");
    try {
      let imageURL = postData?.token_uri;
      if (image && image !== null) {
        const IpfsHash = await pinFileToIPFS(image);
        imageURL = `${PINATA_ASSET_BASE_URL}/${IpfsHash}`;
      }

      const currentNFTMetadata = {
        ...nftItem,
        image: imageURL,
        blockchain: nftItem?.blockchain
          ? nftItem.blockchain
          : postData.blockchain,
        creator: address,
        owner: address,
      };

      try {
        setLoadMsg("Uploading JSON to the IPFS");
        const IpfsHash1 = await pinJsonToIPFS(currentNFTMetadata);
        setLoadMsg("Registering nft");
        let reqPostData = {};
        reqPostData = {
          ...postData,
          ...nftItem,
          token_uri: imageURL,
          file_type: image.type,
          chain_token_uri: `${PINATA_ASSET_BASE_URL}/${IpfsHash1}`,
        };

        try {
          const result = await handleMutation(reqPostData);
          if (result?.data?.createNFT?.id > 0) {
            showSnackMessage(t("MSG_SAVE_ASSET_SUCCESS"), "success");
            const data = result?.data?.createNFT;
            setTimeout(() => {
              navigate(`/explore/${data?.collection?.id}/${data?.token_id}`);
            }, 1000);
          } else {
            showSnackMessage(result?.data?.createNFT?.message, "error");
          }
          setIsCreatingNft(false);
        } catch (e) {
          console.log(e);
          showSnackMessage(t("MSG_SAVE_ASSET_ISSUE"), "error");
          setIsCreatingNft(false);
          return 0;
        }
      } catch (e) {
        console.log(e);
        setIsCreatingNft(false);
        showSnackMessage(t("MSG_UPLOAD_IPFS_JSON_ISSUE"), "error");
        return 0;
      }
    } catch (e) {
      console.log(e);
      setIsCreatingNft(false);
      showSnackMessage(t("MSG_UPLOAD_IPFS_IMAGE_ISSUE"), "error");
      return 0;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Loader isLoading={isCreatingNft} message={loadMsg} darkMode={darkMode} />
      <SwitchNetworkDialog
        open={switchNetworkModalOpen}
        handleClose={() => setSwitchNetworkModalOpen(false)}
        onClickSwitchNetwork={onClickSwitchNetwork}
        blockchain={postData.blockchain}
        darkMode={darkMode}
      />

      <AlertDialog
        open={isSubmit}
        handleClose={() => setIsSubmit(false)}
        handleAgree={handleAcceptSubmit}
        darkMode={darkMode}
        title={"ALERT_TITLE"}
        content={"ASK_CREATE_ASSET"}
      />
      {!isMobile ? (
        <Box zIndex={1000} sx={{ px: "144px" }}>
          <Box sx={{ mt: 10.5, mb: 4 }}></Box>
          <Box
            sx={{
              backgroundColor: `${darkMode ? "#171C26" : "#ffffff"}`,
              pr: 6,
              pl: 4,
              py: 4,
              borderRadius: "16px",
            }}
          >
            <Box sx={{ mt: 5 }}>
              <Grid
                zIndex={1000}
                container
                columns={{ md: 12 }}
                spacing={{ md: 15 }}
              >
                <Grid item md={12}>
                  <UploadComponent
                    image={postData?.image}
                    darkMode={darkMode}
                    setUploadImage={setImage}
                    label="UPLOAD_MULTIMEDIA_LABEL"
                    description="UPLOAD_MULTIMEDIA_LABEL_DESCRIPTION"
                  />
                  <Box>
                    <InputComponent
                      darkMode={darkMode}
                      label="NFT_NAME"
                      name="name"
                      isRequired={true}
                      handleChange={handleChanged}
                      defaultValue={postData.name}
                    />
                    <InputComponent
                      darkMode={darkMode}
                      label="ADD_EXTERNAL_LINK"
                      type="url"
                      handleChange={handleChanged}
                      description="ADD_EXTERNAL_LINK_DESCRIPTION"
                      name="link"
                      defaultValue={postData.link}
                    />
                    <Stack direction="column" spacing={2} sx={{ mt: 3 }}>
                      <TextAreaExtend
                        label="DESCRIPTION"
                        name="description"
                        handleChange={handleChanged}
                        darkMode={darkMode}
                        defaultValue={postData.description}
                      />
                    </Stack>
                    <CollectionSelect
                      name="collections"
                      isRequired={true}
                      handleChange={handleSelectChanged}
                      defaultValue={postData.collection_id}
                    />
                    <InputComponent
                      darkMode={darkMode}
                      label="SUPPLY"
                      handleChange={handleChanged}
                      type="number"
                      defaultValue="1"
                      description="SUPPLY_DESCRIPTION"
                    />
                    <InputComponent
                      darkMode={darkMode}
                      name="blockchain"
                      handleChange={handleChanged}
                      label="BLOCKCHAIN"
                      value={chainNet}
                    />
                    <InputComponent
                      darkMode={darkMode}
                      label="FREEZE_YOUR_METADATA"
                      description="FREEZE_YOUR_METADATA_DESCRIPTION"
                      defaultValue="To freeze your metadata, you must create your item first."
                      disabled="1"
                    />
                  </Box>
                </Grid>
              </Grid>
              <GradientButtonPrimary
                type="submit"
                sx={{ display: "flex", alignItems: "center", gap: 2, mt: 5 }}
                onClick={handleSubmit}
              >
                <Typography component="span" color="#ffffff">
                  <MdAddToPhotos />
                </Typography>
                <Typography variant="body2" component="span">
                  {t("CREATE_ASSET")}
                </Typography>
              </GradientButtonPrimary>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box>
          <Box
            sx={{
              position: "relative",
              px: "24px",
            }}
          >
            <Box
              sx={{
                position: "fixed",
                top: "0%",
                left: "44%",
                transform: "translate(-50%, -50%)",
                zIndex: "10000",
                mt: 6,
              }}
            >
              <Box
                pb={2}
                ml={7}
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap={2}
              >
                <Typography variant="subtitle1" color="secondary" mt={1.2}>
                  <MdAddToPhotos fontSize={20} />
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="secondary"
                  component="div"
                  sx={{
                    borderBottom: `${
                      darkMode ? "2px solid #ffffff" : "1px solid #171c26"
                    }`,
                  }}
                >
                  {t("CREATE_ASSET")}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              mt: 1,
              py: 2,
              borderRadius: "16px",
              px: 3,
            }}
          >
            <Box>
              <Box>
                <UploadComponent
                  image={postData?.image}
                  darkMode={darkMode}
                  setUploadImage={setImage}
                  label="UPLOAD_MULTIMEDIA_LABEL"
                  description="UPLOAD_MULTIMEDIA_LABEL_DESCRIPTION"
                />
              </Box>
              <Stack direction="column" spacing={2} sx={{ mt: 3 }}>
                <InputComponent
                  darkMode={darkMode}
                  label="NFT_NAME"
                  name="name"
                  isRequired={true}
                  handleChange={handleChanged}
                  defaultValue={postData.name}
                />
              </Stack>
              <Stack direction="column" spacing={2} sx={{ mt: 3 }}>
                <InputComponent
                  darkMode={darkMode}
                  label="ADD_EXTERNAL_LINK"
                  type="url"
                  handleChange={handleChanged}
                  description="ADD_EXTERNAL_LINK_DESCRIPTION"
                  name="link"
                  defaultValue={postData.link}
                />
              </Stack>
              <Stack direction="column" spacing={2} sx={{ mt: 3 }}>
                <TextAreaExtend
                  label="DESCRIPTION"
                  name="description"
                  handleChange={handleChanged}
                  darkMode={darkMode}
                  defaultValue={postData.description}
                />
              </Stack>
              <CollectionSelect
                name="collections"
                isRequired={true}
                handleChange={handleSelectChanged}
                defaultValue={postData.collection_id}
              />
              <InputComponent
                darkMode={darkMode}
                label="SUPPLY"
                description="SUPPLY_DESCRIPTION"
              />
              <InputComponent
                darkMode={darkMode}
                name="blockchain"
                handleChange={handleChanged}
                label="BLOCKCHAIN"
                value={chainNet}
              />
              <InputComponent
                darkMode={darkMode}
                label="FREEZE_YOUR_METADATA"
                description="FREEZE_YOUR_METADATA_DESCRIPTION"
                defaultValue="To freeze your metadata, you must create your item first."
                disabled="1"
              />
              <GradientButtonPrimary
                type="submit"
                sx={{ display: "flex", alignItems: "center", gap: 2, mt: 5 }}
                onClick={handleSubmit}
              >
                <Typography component="span" color="secondary">
                  <MdAddToPhotos />
                </Typography>
                <Typography variant="body2" component="span">
                  {t("CREATE_ASSET")}
                </Typography>
              </GradientButtonPrimary>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default CreateAssets;

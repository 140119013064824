import { Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";

export const MobileTab = ({ darkMode, tabValue, handleChange }) => {
  const { t } = useTranslation();
  const MobileTabs = styled(Tabs)({
    border: "none",
    backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
    "& .MuiTabs-indicator": {
      backgroundColor: "inherit",
    },
  });

  const MobileTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
    "&.Mui-selected": {
      color: "#ffffff",
      backgroundColor: "#01D4FA",
      borderRadius: "4px",
      fontWeight: theme.typography.fontWeightMedium,
    },
  }));

  return (
    <Box mb={2}>
      <MobileTabs
        darkMode={darkMode}
        textColor="secondary"
        indicatorColor="secondary"
        value={tabValue}
        onChange={handleChange}
      >
        <MobileTab
          sx={{ color: "gray", textTransform: "capitalize" }}
          label={t("RECENT_BID")}
        />
        <MobileTab
          sx={{ color: "gray", ml: 3, textTransform: "capitalize" }}
          label={t("HISTORY")}
        />
      </MobileTabs>
    </Box>
  );
};

import {
    ETH_CURRENT_NET,
    BINANCE_CURRENT_NET,
    POLYGON_CURRENT_NET
} from "../configs/constant";

import { getNetwork, switchNetwork } from '@wagmi/core'

export const switchCurrentNetwork = async (blockchain) => {
    const currentChainId = blockchain === 'Binanace'
                            ? BINANCE_CURRENT_NET.chainId
                            : blockchain === 'Polygon'
                            ? POLYGON_CURRENT_NET.chainId
                            : ETH_CURRENT_NET.chainId;

    return await switchNetwork({ chainId: currentChainId });
};

export const confirmCurrentNetwork = async (blockchain) => {
    const { chain } = getNetwork();
    const currentChainId = blockchain === 'Binanace' 
                            ? BINANCE_CURRENT_NET.chainId
                            : blockchain === 'Polygon' 
                            ? POLYGON_CURRENT_NET.chainId
                            : ETH_CURRENT_NET.chainId;

    return currentChainId === chain.id;
};
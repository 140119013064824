import React, { memo } from "react";
import {
  Avatar,
  Box,
  Divider,
  Grid,
  Grow,
  Stack,
  Typography,
} from "@mui/material";
import { HiOutlineClock } from "react-icons/hi";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { useTranslation } from "react-i18next";

import styles from "components/NftCard/NftCard.module.css";
import { chainIcons } from "configs/constant";
import { NftCardBackground } from "components/NftCard/NftCardBackground";
import { defaultPngAvatar } from "configs/constant";

const NftCard = ({ nft, handleDetails, darkMode }) => {
  const {
    token_uri,
    name,
    blockchain,
    uploaded,
    last_price,
    isLike,
    owner,
    likes,
  } = nft; // Getting the data from the props
  const { t } = useTranslation();
  return (
    <Grow in={true} style={{ transformOrigin: "0 0 0" }}>
      <Grid className={styles.nftCard} item xs={1} sm={6} md={3}>
        <div className={styles.nftCard}>
          <Box className={styles.gradientBorderBox}>
            <Box
              className={styles.cardBody}
              // sx={{
              //   border: "1px solid #eeeeee",
              // }}
              bgcolor={darkMode ? "#121212" : "#ffffff"}
              onClick={(e) => handleDetails(e)}
            >
              <NftCardBackground
                darkMode={darkMode}
                name={name}
                tokenURI={token_uri}
              />
              <Box
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "-28px",
                  marginRight: "8px",
                  marginBottom: "16px",
                }}
              >
                <img
                  src={chainIcons[blockchain]?.logo}
                  // hidden={polygonLoading}
                  // onLoad={setPolygonLoading(false)}
                  alt="Polygon Icon"
                  mb={-0.5}
                  width="15"
                  height={"15"}
                />
              </Box>
              <Box className={styles.nftCardBody}>
                <Typography
                  variant="subtitle1"
                  component="h2"
                  color="secondary.main"
                  fontWeight={500}
                >
                  {name}
                </Typography>
                <Typography
                  variant="caption"
                  gutterBottom
                  color="secondary.main"
                >
                  Owner: {owner ? owner?.username : ""}
                </Typography>
                {/* <Typography variant="subtitle2" component="p" color="gray">
                  {t("PRICE")}
                </Typography> */}
                <Stack
                  variant="h5"
                  component="div"
                  color="secondary.main"
                  mb={1}
                  fontWeight={500}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Typography
                    variant="body1"
                    component="span"
                    fontSize={"15px"}
                  >
                    {" "}
                    {" $"}
                    {`${last_price}`}
                  </Typography>
                </Stack>
                {/* <Divider
                  style={{ backgroundColor: "#8E8E8E", margin: "5px 0" }}
                />
                <Box className={styles.nftCardInfo}>
                  <Box className={styles.nftCardOwner}>
                    <Box>
                      <Avatar
                        sx={{
                          border: `2px solid ${
                            darkMode ? "#ffffff" : "#01D4FA"
                          }`,
                        }}
                        src={owner?.image ? owner?.image : defaultPngAvatar}
                        // src={defaultPngAvatar}
                        alt={owner ? owner?.username : ""}
                      />
                    </Box>
                    <Stack direction="column">
                      <Typography variant="caption" gutterBottom color="gray">
                        {t("OWNER")}
                      </Typography>
                      <Typography
                        variant="caption"
                        gutterBottom
                        color="secondary.main"
                      >
                        {owner ? owner?.username : ""}
                      </Typography>
                    </Stack>
                  </Box>
                </Box> */}
                {/* <Divider sx={{ my: 1 }} className={styles.dividerStyle} />
                <Box className={styles.nftCardFooter}>
                  <Stack direction="row" gap={2} alignItems="center">
                    <HiOutlineClock
                      style={{ color: `${darkMode ? "#ffffff" : "#121212"}` }}
                    />
                    <Typography variant="caption" component="span" color="gray">
                      {`${uploaded?.days}d ${uploaded?.hours}` + t("HOURS_AGO")}
                    </Typography>
                  </Stack>
                  <Stack direction="row" gap={2} alignItems="center">
                    {isLike === 0 ? (
                      <AiOutlineHeart
                        style={{ color: `${darkMode ? "#ffffff" : "#121212"}` }}
                      />
                    ) : (
                      <AiFillHeart
                        style={{ color: `${darkMode ? "#ffffff" : "#121212"}` }}
                      />
                    )}
                    <Typography variant="caption" component="span" color="gray">
                      {likes}
                    </Typography>
                  </Stack>
                </Box> */}
              </Box>
            </Box>
          </Box>
        </div>
      </Grid>
    </Grow>
  );
};

export default memo(NftCard);

import { styled } from "@mui/material/styles";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Tabs, Tab } from "@mui/material";

const StyledMobileTab = ({
  darkMode,
  filteredSeller,
  isAdmin,
  tabValue,
  handleChange,
}) => {
  const { t } = useTranslation();
  const [role, setRole] = useState(isAdmin);
  useEffect(() => {
    setRole(isAdmin);
  }, [isAdmin]);
  const MobileTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
    color: `${darkMode ? "#ffffff" : "#040404"}`,
    backgroundColor: `${darkMode ? "#171c26" : "#ffffff"}`,
    borderRadius: "4px",
    "&.Mui-selected": {
      color: "#ffffff",
      backgroundColor: "#01D4FA",
      borderRadius: "4px",
      fontWeight: theme.typography.fontWeightMedium,
    },
  }));

  const MobileTabs = styled(Tabs)({
    border: "none",
    backgroundColor: `${darkMode ? "#040404" : "#ffffff"}`,
    "& .MuiTabs-indicator": {
      backgroundColor: "inherit",
    },
  });

  return (
    <MobileTabs
      variant="scrollable"
      textColor="secondary"
      indicatorColor="secondary"
      value={tabValue}
      onChange={handleChange}
      centered
    >
      {role && (
        <MobileTab
          sx={{
            color: "gray",
            textTransform: "capitalize",
            display: "block",
            mr: 2,
            zIndex: "601",
          }}
          label={`${t("CREATED")}`}
        />
      )}
      <MobileTab
        sx={{
          color: "gray",
          textTransform: "capitalize",
          display: "block",
          mr: 2,
          zIndex: "601",
        }}
        label={`${t("OWNED")}`}
      />
      <MobileTab
        sx={{
          color: "gray",
          textTransform: "capitalize",
          display: "block",
          mr: 2,
          zIndex: "601",
        }}
        label={`${t("AUCTION_WON")}`}
      />
      <MobileTab
        sx={{
          color: "gray",
          textTransform: "capitalize",
          display: "block",
          mr: 2,
          zIndex: "601",
        }}
        label={`${t("CLAIMED")}`}
      />
      <MobileTab
        sx={{
          color: "gray",
          textTransform: "capitalize",
          display: "block",
          mr: 2,
          zIndex: "601",
        }}
        label={`${t("COLLECTED")}`}
      />
      <MobileTab
        sx={{
          color: "gray",
          textTransform: "capitalize",
          display: "block",
          mr: 2,
          zIndex: "601",
        }}
        label={`${t("ACTIVITY")}`}
      />
      {/* <Tab
                sx={{
                color: 'gray',
                textTransform: 'capitalize',
                display: 'block',
                mr: 2,
                zIndex: "601",
                }}
                label={`${t("MORE")}`}
            /> */}
    </MobileTabs>
  );
};

export default StyledMobileTab;

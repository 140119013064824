import {
    createContractConfig,
    deployNewContract,
    createContractWrite,
    getSignatureOVerify,
    getSignaturePlain,
    getExchangeRewardAddress
} from './deploy'

export const appInterface = {
    createContractConfig: createContractConfig,
    deployNewContract: deployNewContract,
    createContractWrite: createContractWrite,
    getSignatureOVerify: getSignatureOVerify,
    getSignaturePlain: getSignaturePlain,
    getExchangeRewardAddress: getExchangeRewardAddress,
};
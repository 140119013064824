import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTranslation } from "react-i18next";

export default function AlertDialog({
  open,
  handleClose,
  handleAgree,
  title,
  content,
  darkMode,
}) {
  const { t } = useTranslation();
  return (
    <div>
      <Dialog
        sx={{ zIndex: 100000000 }}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t(title)}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t(content)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            sx={{
              p: 1,
              // backgroundColor: `${darkMode ? "#383838" : "#ffffff"}`,
              color: `${darkMode ? "#ffffff" : "#383838"}`,
            }}
          >
            {t("CANCEL")}
          </Button>
          <Button
            onClick={handleAgree}
            autoFocus
            sx={{
              p: 1,
              // backgroundColor: `${darkMode ? "#171C26" : "#ffffff"}`,
              color: `${darkMode ? "#ffffff" : "#383838"}`,
            }}
          >
            {t("CONFIRM")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

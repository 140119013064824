import React from "react";
import { Box } from "@mui/system";
import { Grid, Stack, Typography } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { useNavigate } from "react-router-dom";

const CollectionCard = ({ darkMode, collection, sellerCollection, id }) => {
  const navigate = useNavigate();
  return (
    <Grid sx={{ zIndex: 10 }} item xs={1} sm={6} md={3}>
      <Box
        elevation={1}
        sx={{ borderRadius: "10px" }}
        bgcolor={darkMode ? "#ffffff" : "#ffffff"}
        color={darkMode ? "#ffffff" : "#ffffff"}
        style={{}}
      >
        {collection?.logo ? (
          <React.Fragment>
            {darkMode ? (
              <Box
                onClick={(e) => {
                  navigate(`collection/${id}`);
                }}
                sx={{
                  width: "100%",
                  aspectRatio: 1,
                  backgroundImage: `linear-gradient(0deg, #121212 0%, rgba(28, 28, 28, 0) 100%) ,url(${collection?.logo})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  position: "relative",
                  cursor: "pointer",
                  borderRadius: "20px",
                }}
              ></Box>
            ) : (
              <Box
                onClick={(e) => navigate(`collection/${id}`)}
                sx={{
                  width: "100%",
                  aspectRatio: 1,
                  backgroundImage: `linear-gradient(0deg, #121212 0%, rgba(28, 28, 28, 0) 100%), url(${collection?.logo})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  position: "relative",
                  cursor: "pointer",
                  borderRadius: "20px",
                }}
              ></Box>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {darkMode ? (
              <Box
                onClick={(e) => navigate(`collection/${id}`)}
                sx={{
                  width: "100%",
                  height: "250px",
                  backgroundImage: `linear-gradient(0deg, #121212 0%, rgba(28, 28, 28, 0) 100%) ,url(${sellerCollection?.nftworkImage})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  position: "relative",
                  cursor: "pointer",
                }}
              ></Box>
            ) : (
              <Box
                onClick={(e) => navigate(`collection/${id}`)}
                sx={{
                  width: "100%",
                  height: "250px",
                  backgroundImage: `linear-gradient(0deg, #121212 0%, rgba(28, 28, 28, 0) 100%) ,url(${sellerCollection?.nftworkImage})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  position: "relative",
                  cursor: "pointer",
                }}
              ></Box>
            )}
          </React.Fragment>
        )}
        <Box position="relative" px={1.5}>
          <Box sx={{ mt: 1, width: "100%" }}>
            <Typography variant="h6" color="#000" sx={{ fontSize: "18px" }}>
              {collection?.name}
            </Typography>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ fontSize: "12px" }}
            >
              <Typography variant="body2" color="#000">
                Creator: {collection?.creator.username}
              </Typography>
              <Typography variant="body2" color="#000">
                {`${collection?.totalNft} NFTs`}
              </Typography>
            </Stack>
          </Box>
        </Box>
        <Stack
          sx={{
            color: `${
              darkMode ? "rgba(256,256, 256, 0.9)" : "rgba(0, 0, 0, 0.9)"
            }`,
            mt: 0,
            px: 1.5,
          }}
          direction="row"
          justifyContent="space-between"
        >
          <Stack direction="row" gap={2} alignItems="center">
            <AccessTimeIcon sx={{ heigth: 18, width: 18 }} />{" "}
            <Typography variant="caption">
              {`${collection?.uploaded?.days}d ${collection?.uploaded?.hours} hr ago`}
            </Typography>
          </Stack>
          <Stack direction="row" gap={2} alignItems="center">
            <FavoriteBorderIcon sx={{ heigth: 18, width: 18 }} />{" "}
            <Typography variant="caption">
              {collection?.likes ? collection?.likes : sellerCollection?.likes}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </Grid>
  );
};

export default CollectionCard;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/system";
import {
  Button,
  Container,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import AtIcon from "assets/Icons/atIcon.svg";
import LockIcon from "assets/Icons/lockIcon.svg";
import { useLocation, Navigate, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

import { login } from "slices/auth.slice";
import { MobileSignInPage } from "./MobileSignInPage";

const SignInPage = ({ darkMode }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const showSnackMessage = (msg, variant) => {
    enqueueSnackbar(msg, { variant });
  };

  const handleSignInUser = (email, password, navigate, location) => {
    try {
      dispatch(login({ email, password }))
        .unwrap()
        .then(() => {
          showSnackMessage(t("LOGIN_SUCCESS"), "success");
          setTimeout(() => {
            const destination = location?.state?.from || "/";
            navigate(destination);
          }, 1000);
        })
        .catch((e) => {
          showSnackMessage(t("LOGIN_FAILED"), "error");
        });
    } catch (e) {
      showSnackMessage(t("LOGIN_FAILED"), "error");
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object({
      email: Yup.string().email("Enter a valid email").required("Required"),
      password: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      handleSignInUser(values.email, values.password, navigate, location);
    },
  });

  if (isLoggedIn) {
    return <Navigate to="/profile/user-profile" />;
  }

  return (
    <React.Fragment>
      {!isMobile ? (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          px={5}
        >
          <Container>
            <Box
              component="form"
              onSubmit={formik.handleSubmit}
              color={darkMode ? "#ffffff" : "#121212"}
              width="100%"
            >
              {/* <Typography variant="h3" fontWeight={500} textAlign="center">
                Sign in to NFT Rewards Marketplace
              </Typography> */}
              <Stack mt={5} direction="column" gap={4}>
                <Box>
                  <TextField
                    autoComplete="off"
                    id="email"
                    name="email"
                    type="email"
                    variant="outlined"
                    placeholder="example@email.com"
                    label="Email Address"
                    color="pink"
                    autoFocus
                    fullWidth
                    InputProps={{
                      classes: {
                        input: `${
                          darkMode ? "inputFieldAuth" : "inputFieldAuthLight"
                        }`,
                      },

                      endAdornment: (
                        <InputAdornment position="end">
                          {<img src={AtIcon} alt="..." />}
                        </InputAdornment>
                      ),
                    }}
                    {...formik.getFieldProps("email")}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <Typography
                      mt={1}
                      component="p"
                      color="error"
                      variant="caption"
                    >
                      {formik.errors.email}
                    </Typography>
                  ) : null}
                </Box>
                <Box>
                  <TextField
                    id="password"
                    name="password"
                    type="password"
                    variant="outlined"
                    placeholder="Enter your top secret password"
                    label="Password"
                    color="pink"
                    fullWidth
                    InputProps={{
                      classes: {
                        input: `${
                          darkMode ? "inputFieldAuth" : "inputFieldAuthLight"
                        }`,
                      },

                      endAdornment: (
                        <InputAdornment position="end">
                          {<img src={LockIcon} alt="..." />}
                        </InputAdornment>
                      ),
                    }}
                    {...formik.getFieldProps("password")}
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <Typography
                      mt={1}
                      component="p"
                      color="error"
                      variant="caption"
                    >
                      {formik.errors.password}
                    </Typography>
                  ) : null}
                </Box>
              </Stack>
              <Box mt={4}>
                <Button
                  type="submit"
                  variant="contained"
                  color="black"
                  fullWidth
                  sx={{
                    py: 1.5,
                    fontSize: "1rem",
                    background: "#000000",
                    color: "#FFFFFF",
                    borderRadius: "4rem",
                  }}
                >
                  Sign In
                </Button>
              </Box>
            </Box>
          </Container>
        </Stack>
      ) : (
        <MobileSignInPage
          darkMode={darkMode}
          handleSignInUser={handleSignInUser}
        />
      )}
    </React.Fragment>
  );
};

export default SignInPage;


const getDefaultValue = (value, defaultValue='') => {
    return value ? value : defaultValue
}

export const generateInitialState = (asset) => {
    return {
        auction_winner: getDefaultValue(asset?.auction_winner, ""),
        blockchain: getDefaultValue(asset?.blockchain, "Polygon"),
        category: getDefaultValue(asset?.category, ""),
        chain_token_uri: getDefaultValue(asset?.chain_token_uri, ""),
        collection_id: getDefaultValue(asset?.collection_id, 0),
        collection_name: getDefaultValue(asset?.collection_name, ""),
        created_at: getDefaultValue(asset?.created_at, ""),
        creator: getDefaultValue(asset?.creator, 1),
        description: getDefaultValue(asset?.description, ""),
        file_type: getDefaultValue(asset?.file_type, ""),
        icon: getDefaultValue(asset?.icon, ""),
        imported: getDefaultValue(asset?.imported, 0),
        last_price: getDefaultValue(asset?.last_price, 0.0),
        likes: getDefaultValue(asset?.likes, 0),
        link: getDefaultValue(asset?.link, ""),
        name: getDefaultValue(asset?.name, ""),
        on_chain: getDefaultValue(asset?.on_chain, 0),
        owner: getDefaultValue(asset?.owner, 0),
        blockchain_onwer: getDefaultValue(asset?.blockchain_onwer, 0),
        quantity: getDefaultValue(asset?.quanty, 1),
        royalty: getDefaultValue(asset?.royalty, 0),
        token_id: getDefaultValue(asset?.token_id, 0),
        token_uri: getDefaultValue(asset?.token_uri, ""),
        updated_at: getDefaultValue(asset?.updated_at, ""),
        contract: getDefaultValue(asset?.contract, ""),
    };
}

export const initialState = (asset) => {
    return {
        name: getDefaultValue(asset?.name, ""),
        link: getDefaultValue(asset?.link, ""),
        description: getDefaultValue(asset?.description, ""),
        collection_id: getDefaultValue(asset?.collection_id, ""),
        token_uri: getDefaultValue(asset?.token_uri, ""),
        creator: getDefaultValue(asset?.creator, ""),
        category: getDefaultValue(asset?.category, ""),
        chain_token_uri: getDefaultValue(asset?.chain_token_uri, ""),
    }
}

export default generateInitialState;

export function timestampToDate(timestamp) {
  let u = new Date(timestamp * 1000);
  return (
    u.getUTCFullYear() +
    "-" +
    ("0" + (u.getUTCMonth() + 1)).slice(-2) +
    "-" +
    ("0" + u.getUTCDate()).slice(-2) +
    " " +
    ("0" + u.getUTCHours()).slice(-2) +
    ":" +
    ("0" + u.getUTCMinutes()).slice(-2) +
    ":" +
    ("0" + u.getUTCSeconds()).slice(-2) +
    "." +
    (u.getUTCMilliseconds() / 1000).toFixed(3).slice(2, 5)
  );
}
export function remainDate(timestamp) {
  const differenceSeconds = timestamp - Math.floor(Date.now() / 1000);
  if (differenceSeconds < 0) {
    return 0;
  } else {
    const diff = differenceSeconds / 86400;
    return Math.ceil(diff);
  }
}

export const pastTime = (timestamp) => {
  timestamp = Number(timestamp);
  const differenceSeconds = Math.floor(Date.now() / 1000) - timestamp;
  const d = Math.floor(differenceSeconds / (3600 * 24));
  const h = Math.floor((differenceSeconds % (3600 * 24)) / 3600);
  const m = Math.floor((differenceSeconds % 3600) / 60);
  const s = Math.floor(differenceSeconds % 60);

  const dDisplay = d > 0 ? d + (d === 1 ? " day" : " days") : "";
  const hDisplay = h > 0 ? h + (h === 1 ? " hour" : " hours") : "";
  const mDisplay = m > 0 ? m + (m === 1 ? " minute" : " minutes") : "";
  const sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
  const differenceTime =
    d > 0
      ? `${dDisplay} ${hDisplay} ago`
      : h > 0
      ? `${hDisplay} ${mDisplay} ago`
      : m > 0
      ? `${mDisplay} ${sDisplay} ago`
      : `${sDisplay} ago`;

  return differenceTime;
};

export const getEndTime = (expirationDays, time) => {
  const currentDate = new Date(
    new Date().getUTCFullYear(),
    new Date().getUTCMonth(),
    new Date().getUTCDate()
  );

  const endtime =
    Math.floor(currentDate.getTime() / 1000) +
    parseInt(time.split(":")[0]) * 3600 +
    parseInt(time.split(":")[1]) * 60 +
    expirationDays * 86400;

  return endtime;
};

export const getExpiration = (timestamp) => {
  timestamp = Number(timestamp);
  const differenceSeconds = timestamp - Math.floor(Date.now() / 1000);
  const d = Math.floor(differenceSeconds / (3600 * 24));
  const h = Math.floor((differenceSeconds % (3600 * 24)) / 3600);
  const m = Math.floor((differenceSeconds % 3600) / 60);
  const s = Math.floor(differenceSeconds % 60);
  const dDisplay = d > 0 ? d + 1 + "days" : "";
  const hDisplay = h > 0 ? h + (h === 1 ? " hour" : " hours") : "";
  const mDisplay = m > 0 ? m + (m === 1 ? " minute" : " minutes") : "";
  const sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
  const differenceTime =
    d > 0
      ? `${dDisplay}`
      : h > 0
      ? `${hDisplay} ${mDisplay}`
      : m > 0
      ? `${mDisplay} ${sDisplay}`
      : `${sDisplay}`;
  return differenceTime;
};

export const getEndTimeConvert = (timeStamp) => {
  let tmp = Number(timeStamp) * 1000;
  var date = new Date(tmp);
  // console.log(date);
  return date.toLocaleString();
};

export const isMobileDevice = () => {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
};

export const getLocalDateTime = (timestamp) => {
  const dateTime = new Date(timestamp * 1000);
  const [month, day, year] = dateTime.toLocaleDateString().split('/');
  const localDateString = [year, month, day].join(':');
  const localTimeString = dateTime.toLocaleTimeString([], { hour12: false, hour: '2-digit', minute: '2-digit', second: '2-digit' });
  return [localDateString, localTimeString].join('T');
}

import React, { useState } from "react";
import {
  Avatar,
  Box,
  Divider,
  Grid,
  Stack,
  Typography,
  Grow,
  useMediaQuery,
  IconButton,
  Zoom,
  MenuItem,
  Skeleton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { HiOutlineClock } from "react-icons/hi";
import { AiOutlineHeart } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import CountDownBoard from "components/CountDownBoard/CountDownBoard";
import { add } from "date-fns";
import AddToCollectionModal from "components/Seller/SellerDetailsOwnedModals/AddToCollectionModal";
import DeleteFromCollection from "components/Seller/SellerDetailsOwnedModals/DeleteFromCollection";
import RemoveFromCollection from "components/Seller/SellerDetailsOwnedModals/RemoveFromCollection";

// Styles
import styles from "components/Seller/SellerDetailsCard/SellerDetailsCard.module.css";
import { GradientButtonPrimary } from "Utils/GradientButtons/GradientButtons";
import { FaThumbsUp } from "react-icons/fa";
import { KeyboardArrowDown } from "@mui/icons-material";
import { StyledMenu } from "components/StyledMenu/StyledMenu";
import { useLocation, useNavigate } from "react-router-dom";
import { defaultPngAvatar } from "configs/constant";

// This should come from the backend API
const futureDate = add(new Date(), {
  days: 7,
  hours: 12,
  minutes: 21,
});

const SellerDetailsCard = ({ sdc, darkMode }) => {
  const { t } = useTranslation();
  const {
    token_uri,
    token_id,
    collection_id,
    name,
    price,
    owner,
    ownerImage,
    created_at,
    likes,
    tag,
  } = sdc;

  const now = new Date();
  const createdDate = new Date(created_at);

  const differenceInMilliseconds = Math.abs(
    now.getTime() - createdDate.getTime()
  );
  const differenceInDay = Math.round(
    differenceInMilliseconds / (1000 * 3600 * 24)
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = useState(true);
  const openMenu = Boolean(anchorEl);
  const location = useLocation();
  let navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  console.log("Here is User", user);
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // Add to collection modal
  const [openAddToCollectionModal, setOpenAddToCollectionModal] =
    useState(false);

  // Delete from collection modal
  const [openDeleteFromCollectionModal, setOpenDeleteFromCollectionModal] =
    useState(false);

  // Remove from collection modal
  const [openRemoveFromCollectionModal, setOpenRemoveFromCollectionModal] =
    useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    //
    <Grow
      in={true}
      style={{ transformOrigin: "0 0 0" }}
      {...(true ? { timeout: 1000 } : {})}
    >
      <Grid
        className={styles.sellerCard}
        item
        xs={1}
        sm={6}
        md={4}
        sx={{ zIndex: 600 }}
      >
        <div
          className={styles.sellerCard}
          onClick={() => navigate(`/explore/${collection_id}/${token_id}`)}
        >
          <Box
            className={styles.sellerCardGradient}
            sx={{ padding: "1px", borderRadius: "20px" }}
          >
            <Box
              bgcolor={darkMode ? "#121212" : "#ffffff"}
              style={{
                display: "flex",
                flexDirection: "column",
                borderRadius: "20px",
                padding: "1rem",
                zIndex: "10",
                cursor: "pointer",
                position: "relative",
                boxShadow: `${!darkMode && "0px 4px 4px rgba(0, 0, 0, 0.25)"}`,
              }}
            >
              <Box style={{ position: "relative" }}>
                <img
                  style={{
                    width: "100%",
                    height: "250px",
                    borderRadius: "10px",
                  }}
                  onLoad={() => setLoading(false)}
                  hidden={loading}
                  src={token_uri}
                  alt={name}
                />
                {loading ? (
                  <Skeleton
                    sx={{
                      backgroundColor: `${darkMode ? "#171C26" : "#ffffff"}`,
                    }}
                    animation="wave"
                    variant="rectangular"
                    style={{
                      width: "100%",
                      height: "250px",
                      borderRadius: "10px",
                    }}
                  />
                ) : null}
              </Box>
              <Box sx={{ mt: 3 }}>
                {tag === "ownedItems" ? (
                  <React.Fragment>
                    {location.pathname === "/user/dummy" && (
                      <Stack
                        mb={1}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography
                          variant="subtitle1"
                          component="h2"
                          color="secondary.main"
                        >
                          {name}
                        </Typography>
                        <IconButton onClick={handleOpenMenu}>
                          <KeyboardArrowDown />
                        </IconButton>
                        <StyledMenu
                          elevation={1}
                          TransitionComponent={Zoom}
                          anchorEl={anchorEl}
                          open={openMenu}
                          onClose={handleCloseMenu}
                          PaperProps={{
                            style: {
                              borderRadius: "10px",
                              backgroundColor: `${
                                darkMode ? "#121212" : "#ffffff"
                              }`,
                              color: `${darkMode ? "#ffffff" : "#121212"}`,
                            },
                          }}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        >
                          <MenuItem
                            onClick={() => {
                              setOpenAddToCollectionModal(true);
                              handleCloseMenu();
                            }}
                          >
                            <Typography variant="subtitle1" fontWeight={400}>
                              {t("ADD_TO_COLLECTION")}
                            </Typography>
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setOpenRemoveFromCollectionModal(true);
                              handleCloseMenu();
                            }}
                          >
                            <Typography variant="subtitle1" fontWeight={400}>
                              {t("BURN_THE_NFT")}
                            </Typography>
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setOpenDeleteFromCollectionModal(true);
                              handleCloseMenu();
                            }}
                          >
                            <Typography variant="subtitle1" fontWeight={400}>
                              {t("REMOVE_FROM_SELL")}
                            </Typography>
                          </MenuItem>
                        </StyledMenu>
                      </Stack>
                    )}
                  </React.Fragment>
                ) : (
                  <Typography
                    variant="subtitle1"
                    component="h2"
                    color="secondary.main"
                    mb={1}
                  >
                    {name}
                  </Typography>
                )}
                <Typography variant="subtitle2" component="p" color="gray">
                  {t("PRICE")}
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="p"
                  color="secondary.main"
                  mb={2}
                >
                  {price}
                </Typography>
                {tag === "auctionItems" ? (
                  <Box>
                    {!isMobile ? (
                      <Box>
                        <CountDownBoard
                          darkMode={darkMode}
                          futureDate={futureDate}
                          isCard={true}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{ position: "absolute", top: "45%", left: "26%" }}
                      >
                        <CountDownBoard
                          darkMode={darkMode}
                          futureDate={futureDate}
                        />
                      </Box>
                    )}
                  </Box>
                ) : null}
                <Divider
                  style={{ backgroundColor: "#8E8E8E", margin: "10px 0" }}
                />
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                    <Box>
                      <Avatar
                        sx={{ border: "2px solid #ffffff" }}
                        src={owner?.image ? owner?.image : defaultPngAvatar}
                        alt={owner?.username}
                      />
                    </Box>
                    <Stack direction="column" alignItems="center">
                      <Typography variant="caption" gutterBottom color="gray">
                        {t("OWNER")}
                      </Typography>
                      <Typography
                        variant="caption"
                        gutterBottom
                        color="secondary.main"
                      >
                        {owner?.username}
                      </Typography>
                    </Stack>
                  </Box>
                </Box>
                {tag === "auctionItems" ? null : (
                  <Box>
                    <Divider
                      style={{ backgroundColor: "#8E8E8E", margin: "10px 0" }}
                    />
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Stack direction="row" gap={2} alignItems="center">
                        <HiOutlineClock
                          style={{
                            color: `${darkMode ? "#ffffff" : "#121212"}`,
                          }}
                        />
                        <Typography
                          variant="caption"
                          component="span"
                          color="gray"
                        >
                          {differenceInDay}
                        </Typography>
                      </Stack>
                      <Stack direction="row" gap={2} alignItems="center">
                        <AiOutlineHeart
                          style={{
                            color: `${darkMode ? "#ffffff" : "#121212"}`,
                          }}
                        />
                        <Typography
                          variant="caption"
                          component="span"
                          color="gray"
                        >
                          {likes}
                        </Typography>
                      </Stack>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </div>
        {tag === "auctionItems" && (
          <div className={styles.auctionCardButton}>
            <Box className={styles.auctionCardButtonBox}>
              <GradientButtonPrimary className={styles.gradientButton}>
                <Typography component="span" color="#ffffff" mt={0.5}>
                  <FaThumbsUp />
                </Typography>
                {!isMobile ? (
                  <Typography variant="body1" component="span">
                    {t("PLACE_YOUR_BID")}
                  </Typography>
                ) : (
                  <Typography variant="body2" component="span" fontSize="14px">
                    {t("PLACE_YOUR_BID")}
                  </Typography>
                )}
              </GradientButtonPrimary>
            </Box>
          </div>
        )}
        <AddToCollectionModal
          open={openAddToCollectionModal}
          handleClose={() => setOpenAddToCollectionModal(false)}
          darkMode={darkMode}
        />
        <DeleteFromCollection
          open={openDeleteFromCollectionModal}
          handleClose={() => setOpenDeleteFromCollectionModal(false)}
          darkMode={darkMode}
        />
        <RemoveFromCollection
          open={openRemoveFromCollectionModal}
          handleClose={() => setOpenRemoveFromCollectionModal(false)}
          darkMode={darkMode}
        />
      </Grid>
    </Grow>
  );
};

export default SellerDetailsCard;

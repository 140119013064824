import { Tab, Tabs } from "@mui/material"
import { useTranslation } from "react-i18next";
import { useState, useEffect } from 'react';

const TabList = ({tabValue, handleChange, isAdmin, filteredSeller}) => {
    const { t } = useTranslation();
    const [role, setRole] = useState(isAdmin);

    useEffect(() => {
        setRole(isAdmin);
    }, [isAdmin]);
    return (
        <Tabs
            textColor='secondary'
            indicatorColor='secondary'
            value={tabValue}
            onChange={handleChange}
            centered
            >
            {role > 0 && <Tab
                sx={{
                color: 'gray',
                textTransform: 'capitalize',
                display: 'block',
                mx: 5,
                zIndex: "601",
                }}
                label={`${t('CREATED')}`}
            />}
            <Tab
                sx={{
                color: 'gray',
                textTransform: 'capitalize',
                display: 'block',
                mx: 5,
                zIndex: "601",
                }}
                label={`${t('OWNED')}`}
            />
            <Tab
                sx={{
                color: 'gray',
                textTransform: 'capitalize',
                display: 'block',
                mx: 5,
                zIndex: "601",
                }}
                label={`${t('AUCTION_WON')}`}
            />
            <Tab
                sx={{
                color: 'gray',
                textTransform: 'capitalize',
                display: 'block',
                mx: 5,
                zIndex: "601",
                }}
                label={`${t('CLAIMED')}`}
            />
            <Tab
                sx={{
                color: 'gray',
                textTransform: 'capitalize',
                display: 'block',
                mx: 5,
                zIndex: "601",
                }}
                label={`${t('COLLECTED')}`}
            />
            <Tab
                sx={{
                color: 'gray',
                textTransform: 'capitalize',
                display: 'block',
                mx: 5,
                zIndex: "601",
                }}
                label={`${t('ACTIVITY')}`}
            />
            {/* <Tab
                sx={{
                color: 'gray',
                textTransform: 'capitalize',
                display: 'block',
                mx: 5,
                zIndex: "601",
                }}
                label={`${t("MORE")}`}
            /> */}
        </Tabs>
    );
}

export default TabList;
import { appInterface } from "Utils/appInterface";
import { parseEther } from "viem";

const [RewardContract, ExchangeContract] = ['reward', 'exchange'];

const contractConfig = {
    deployContract: {
        type: RewardContract,
        isDeploy: true,
    },
    setApprovalForAll: {
        type: RewardContract,
    },
    approve: {
        type: RewardContract,
    },
    mint: {
        type: RewardContract,
    },
    buyToken: {
        type: ExchangeContract,
    },
    transferToken: {
        type: ExchangeContract,
    },
    addCollection: {
        type: ExchangeContract,
    },
};

const sellOrderFields = ['id', 'seller', 'buyer', 'maker', 'collection', 'marketplace', 'creator', 'price', 'expiry', 'nonce', 'uid', 'tokenId', 'collectionId', 'auctionStartAt', 'auctionEndAt', 'sell_type', 'signature'];
const buyOrderFields = ['seller', 'buyer', 'maker', 'collection', 'marketplace', 'creator', 'tokenId', 'blockchain', 'price', 'expiry', 'nonce', 'uid' ];
export const listing = async({tokenInfo, handleSetMsg}) => {
    let contractAddr = tokenInfo?.collection?.contract;
    if (contractAddr === '') {
        const contract = await appEthMethods({
            excuteOption:'deployContract',
            chain: tokenInfo?.blockchain,
            // args: [tokenInfo?.collectionName, tokenInfo?.collectionName]
            args: [tokenInfo?.collection?.name, tokenInfo?.collection?.name],
        });
        contractAddr = contract;
        await appEthMethods({
            excuteOption:'addCollection',
            chain: tokenInfo?.blockchain,
            args: [contractAddr]
        });
    }
    return contractAddr;
}

export const setApprove = async({tokenInfo, contractAddr}) => {
    if (!tokenInfo?.is_approved) {
        await appEthMethods({
            address: contractAddr,
            excuteOption:'setApprovalForAll',
            chain: tokenInfo?.blockchain,
            args: [ExchangeAddress(tokenInfo?.blockchain), true]
        });
        return true;
    }
    return true;
}

export const mint = async({ blockchain, tokenInfo}) => {
    const res = await appEthMethods({
        address: tokenInfo?.collection?.contract,
        excuteOption:'mint',
        chain: tokenInfo?.blockchain,
        args: [tokenInfo.token_id, tokenInfo.chain_token_uri]
    });
    return res;
};

export const buying = async({tokenInfo, handleSetMsg, handleSetApproved }) => {
    if (!tokenInfo.is_approved) {
        await appEthMethods({
            address: tokenInfo?.collection?.contract,
            excuteOption:'setApprovalForAll',
            chain: tokenInfo?.blockchain,
            args: [ExchangeAddress(tokenInfo?.blockchain), true]
        });
        handleSetApproved({
            collection_id: tokenInfo.collection_id,
            token_id: tokenInfo.token_id,
        });

    }
    const model = await generateCurrentModel(tokenInfo);
    const buyOrder = generateBuyOrder(model);
    if (buyOrder.seller === buyOrder.buyer) {
        return true;
    }

    try {
        const res = await appEthMethods({
            address: tokenInfo?.collection.contract,
            excuteOption:'buyToken',
            chain: tokenInfo?.blockchain,
            args: [buyOrder, tokenInfo?.collect?.signature]
        });
        return res;
    } catch (e) {
        return false;
    }
}

export const transfer = async({contractAddress, tokenId, toAddress, blockchain}) => {
    const res = await appEthMethods({
        excuteOption:'transferToken',
        chain: blockchain,
        args: [contractAddress, toAddress, tokenId]
    });

    return res;
}

export const generateCurrentModel = async (tokenInfo, submitData={}) => {
    const [account] = await window.ethereum.request({ method: 'eth_requestAccounts' });
    return {
        id: tokenInfo?.sell?.id,
        seller: tokenInfo.collect.wallet_address,
        buyer: account,
        maker: account,
        collection: tokenInfo?.collection?.contract.toLowerCase(),
        marketplace: ExchangeAddress(tokenInfo.blockchain),
        creator: tokenInfo.collection.creator_wallet_address,
        blockchain: tokenInfo.blockchain,
        price:  parseEther(tokenInfo.collect.price.toString()).toString(),
        expiry: tokenInfo.collect.auction_start_at,
        nonce: 1,
        tokenId: tokenInfo?.token_id,
        collectionId: tokenInfo?.collection_id,
        signature: submitData?.signature ? submitData.signature : tokenInfo?.collect?.signature,
        auctionStartAt: submitData?.startTime ? submitData.startTime : tokenInfo?.collect?.auction_start_at,
        auctionEndAt: submitData?.endTime ? submitData.endTime : tokenInfo?.collect?.auctionEndAt,
        sellType: submitData.sell_type ? submitData.sell_type - 1 : tokenInfo?.collect?.sell_type,
        uid: submitData.uid ? submitData.uid : tokenInfo?.collect?.uid,
    };
}

export const generateSellOrder = (basicModel) => {
    let sellOrder = {};
    for (var i = 0; i < sellOrderFields.length; i++) {
        sellOrder = {
            ...sellOrder,
            [sellOrderFields[i]]: basicModel[sellOrderFields[i]]
        };
    }
    return sellOrder;
}

export const generateBuyOrder = (basicModel) => {
    let buyOrder = {};
    for (var i = 0; i < buyOrderFields.length; i++) {
        buyOrder = {
            ...buyOrder,
            [buyOrderFields[i]]: basicModel[buyOrderFields[i]]
        };
    }

    return buyOrder;
}

export const ExchangeAddress = (chain) => {
    return appInterface.getExchangeRewardAddress(chain);
}

export const appEthMethods = async ({excuteOption, address, chain, args}) => {
    const option = contractConfig[excuteOption];
    const config = appInterface.createContractConfig({address: address, chain: chain, type: option.type, isDeploy: option?.isDeploy});
    if (option.isDeploy) {
        return await appInterface.deployNewContract({abi: config, args: args});
    }

    return await appInterface.createContractWrite(config, excuteOption, args);
}
import {
    Typography,
} from "@mui/material";

import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import ImageComponent from "./components/ImageComponent";

export default function UploadComponent({ image, darkMode, label, description, setUploadImage }) {

    const { t } = useTranslation();

    const attrData = {
        name: "image"
    }

    return (
        <Box component="form">
            <Typography
                color="secondary"
                variant="body2"
                component="p"
                fontWeight={500}
                style={{
                    fontSize: "14px"
                }}
            >
                {t(label)}
            </Typography>
            <Typography
                color="secondary"
                variant="body2"
                component="p"
                fontWeight={500}
                style={{
                    color: `${darkMode ? "#c8c8c8" : "#202020"}`,
                    fontSize: "12px",
                    fontWeight: "500",
                    marginTop: "0px"
                }}
            >
                {t(description)}
            </Typography>
            <Box>
                <ImageComponent defaultImage={image} darkMode={darkMode} attr={attrData} setUploadImage={setUploadImage}/>
            </Box>
        </Box>
    );
}

import { Box } from "@mui/system";
import styles from "components/NftCard/NftCardDetails/NftWork.module.css";
import { NormalInfoDisplay } from "components/NftCard/NftCardDetails/Component/NormalInfoDisplay";
import { CopyNftInfoDisplay } from "components/NftCard/NftCardDetails/Component/CopyNftInfoDisplay";

export const NftBlockchainInfo = ({
  darkMode,
  tokenId,
  blockchain,
  collection,
  isMobile,
}) => {
  const nftDisplayDetails = isMobile
    ? "nftDisplayDetailsMobile"
    : "nftDisplayDetails";
  return (
    <Box
      className={styles[nftDisplayDetails]}
      bgcolor={`${darkMode ? "#171C26" : "#ffffff"}`}
      sx={{
        mt: 3,
      }}
    >
      <Box
        sx={{
          p: 3,
        }}
      >
        <Box gap={1}>
          <CopyNftInfoDisplay
            darkMode={darkMode}
            label={"CONTRACT_ADDRESS"}
            copyValue={collection?.contract}
          >
            {collection?.contract?.substring(0, 6) +
              "..." +
              collection?.contract?.substring(
                collection?.contract?.length - 4,
                collection?.contract?.length
              )}
          </CopyNftInfoDisplay>
          <CopyNftInfoDisplay
            darkMode={darkMode}
            label={"TOKEN_ID"}
            copyValue={tokenId}
          >
            {tokenId}
          </CopyNftInfoDisplay>
          <NormalInfoDisplay
            darkMode={darkMode}
            label={"TOKEN_STANDARD"}
            value={"ERC721"}
          />
          <NormalInfoDisplay
            darkMode={darkMode}
            label={"BLOCKCHAIN"}
            value={blockchain?.toLocaleUpperCase()}
          />
        </Box>
      </Box>
    </Box>
  );
};

import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";

export const AuctionBtnGroup = ({
  darkMode,
  currentTab,
  isMobile,
  handleAuctionTabClicked,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {!isMobile ? (
        <Box
          bgcolor={darkMode ? "#171c26" : "#ffffff"}
          sx={{
            borderRadius: "10px",
            pl: 5,
            py: 1,
            display: "flex",
            alignItems: "center",
            gap: 15,
          }}
        >
          <Button
            color="secondary"
            onClick={() => handleAuctionTabClicked(1)}
            sx={{ textTransform: "capitalize" }}
          >
            <Typography
              sx={
                currentTab === 1
                  ? {
                      borderBottom: `1px solid ${
                        darkMode ? "#ffffff" : "#171C26"
                      }`,
                    }
                  : {}
              }
              variant="body2"
              component="span"
            >
              {t("LIVE_AUCTIONS")}
            </Typography>
          </Button>
          <Button
            color="secondary"
            onClick={() => handleAuctionTabClicked(2)}
            sx={{ textTransform: "capitalize" }}
          >
            <Typography
              sx={
                currentTab === 2
                  ? {
                      borderBottom: `1px solid ${
                        darkMode ? "#ffffff" : "#171C26"
                      }`,
                    }
                  : {}
              }
              variant="body2"
              component="span"
            >
              {t("UPCOMING_AUCTIONS")}
            </Typography>
          </Button>
          <Button
            color="secondary"
            onClick={() => handleAuctionTabClicked(3)}
            sx={{ textTransform: "capitalize" }}
          >
            <Typography
              sx={
                currentTab === 3
                  ? {
                      borderBottom: `1px solid ${
                        darkMode ? "#ffffff" : "#171C26"
                      }`,
                    }
                  : {}
              }
              variant="body2"
              component="span"
            >
              {t("FIXED_AUCTIONS")}
            </Typography>
          </Button>
        </Box>
      ) : (
        <Box sx={{ mt: -8 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Box
              bgcolor={
                currentTab === 1
                  ? {
                      backgroundColor: `#000000`,
                    }
                  : {
                      backgroundColor: `${darkMode ? "#171c26" : "#ffffff"}`,
                    }
              }
              // onClick={() => navigate("/auction?type=live")}
              onClick={() => handleAuctionTabClicked(1)}
              sx={{
                color: `#ffffff`,
                py: 1,
                px: 3,
                borderRadius: "4px",
                zIndex: 1000,
              }}
            >
              <Typography
                variant="body2"
                color="secondary"
                sx={{
                  fontSize: "12px",
                  color: currentTab === 1 ? "#ffffff" : "#000000",
                }}
              >
                {t("LIVE_AUCTIONS")}
              </Typography>
            </Box>
            <Box
              bgcolor={
                currentTab === 2
                  ? {
                      backgroundColor: `#000000`,
                    }
                  : {
                      backgroundColor: `${darkMode ? "#171c26" : "#ffffff"}`,
                    }
              }
              onClick={() => handleAuctionTabClicked(2)}
              sx={{
                color: `#ffffff`,
                py: 1,
                px: 3,
                borderRadius: "4px",
                zIndex: 1000,
              }}
            >
              <Typography
                variant="body2"
                color="secondary"
                sx={{
                  fontSize: "12px",
                  color: currentTab === 2 ? "#ffffff" : "#000000",
                }}
              >
                {t("UPCOMING_AUCTIONS")}
              </Typography>
            </Box>
            <Box
              bgcolor={
                currentTab === 3
                  ? {
                      backgroundColor: `#000000`,
                    }
                  : {
                      backgroundColor: `${darkMode ? "#171c26" : "#ffffff"}`,
                    }
              }
              onClick={() => handleAuctionTabClicked(3)}
              sx={{
                py: 1,
                px: 3,
                borderRadius: "4px",
                zIndex: 1000,
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontSize: "12px",
                  color: currentTab === 3 ? "#ffffff" : "#000000",
                }}
              >
                {t("FIXED_AUCTIONS")}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

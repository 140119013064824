import { useState, useEffect } from "react";
import { IconButton, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";

const InputButtonComponent = ({ darkMode, attr }) => {
  const {
    label,
    type,
    placeholder,
    name,
    color,
    defaultValue,
    handleChange,
    buttonLabel,
  } = attr;
  const [currentValue, setCurrentValue] = useState(defaultValue);

  useEffect(() => {
    if (defaultValue !== currentValue) {
      setCurrentValue((prev) => defaultValue);
    }
  }, [defaultValue]);

  const { t } = useTranslation();
  return (
    <Box sx={{ display: "flex", alignItems: "center" }} color="#46505A">
      <TextField
        sx={{ flex: 1 }}
        color="secondary"
        label={t(label)}
        type={type}
        placeholder={t(placeholder)}
        name={name}
        defaultValue={0.01}
        // defaultValue={currentValue}
        value={currentValue}
        style={{
          color: { color },
          fontSize: "12px",
          fontWeight: "500",
          marginTop: "0px",
        }}
        onChange={handleChange}
        disabled
        inputProps={{
          "aria-label": "search google maps",
          "border-radius": "0px",
        }}
      />
      <IconButton
        type="button"
        sx={{
          p: "10px",
          border: 1,
          borderColor: "secondary",
          borderRadius: "0px",
        }}
        aria-label="search"
      >
        {buttonLabel}
      </IconButton>
    </Box>
  );
};

export default InputButtonComponent;

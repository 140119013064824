import {
    Stack,
    TextField
  } from "@mui/material";
  import React from "react"; 
  import { useTranslation } from "react-i18next";

  const InputComponent = ({
    darkMode,
    label,
    description,
    name,
    type,
    isRequired,
    disabled,
    placeholder,
    defaultValue,
    value,
    handleChange
  }) => {
    const { t } = useTranslation();

    return(
        <Stack direction="column" spacing={2} sx={{mt:3}}>
          <TextField
            type={type}
            label={t(label)}
            name={name}
            required={isRequired}
            color="secondary"
            defaultValue={defaultValue ? defaultValue : ''}
            value = {value}
            placeholder={placeholder}
            disabled = {disabled}
            onChange = {handleChange}
          />

          { description ? (<label
          style={{
              color: `${darkMode ? "#c8c8c8" : "#202020"}`,
              fontSize: "12px",
              fontWeight: "500",
              marginTop: "0px"
          }}
          htmlFor={name}
          >
            {t(description)} {isRequired ? ("*") : ("")}
          </label>) : "" }

        </Stack>
    );
  }

  export default InputComponent;
import { Divider, Stack } from "@mui/material";
import { useState } from "react";
import InputButtonComponent from "components/NftCard/NftCardDetails/InputButtonComponent";
import { useTranslation } from "react-i18next";
import { SingleDatePicker } from "components/NftCard/NftCardDetails/InputDateRangePicker";
import SelectComponent from "Pages/CreateAssets/components/SelectComponent";

import styles from "components/NftCard/NftCardDetails/NftWork.module.css";

export const OpenedComponent = ({
  isMobile,
  darkMode,
  tokenInfo,
  handleStateChange,
}) => {
  const [duration, setDuration] = useState(15);
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState("0:0");

  const { t } = useTranslation();

  function timeslots(interval = 15) {
    const result = [];
    let i = 0;
    let slices = (24 * 60) / interval;
    let hour = 0,
      minute = 0;

    while (slices > 0) {
      const time = new Date();
      time.setHours(hour);
      time.setMinutes(minute);
      result.push({
        id: i,
        value: `${hour}:${minute < 10 ? "0" + minute : minute}`,
        name: time.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
      });

      minute = minute + interval;
      if (minute >= 60) {
        hour += Math.floor(minute / 60);
        minute = minute % 60;
      }

      slices--;
      i++;
    }

    return result;
  }

  const attrData = {
    label: "STARTING_PRICE",
    type: "number",
    placeholder: "CREATE_ASSET_PLACEHOLDER_ENTER_NFT_BASE_PRICE",
    name: "price",
    buttonLabel: "US Dollar",
    // defaultValue: tokenInfo?.last_price,
    defaultValue: 0.01,
    disabled: true,
    color: `${darkMode ? "#ffffff" : "#121212"}`,
    handleChange: (e) => {
      const { name, value } = e?.target;
      handleStateChange(name, value);
    },
  };

  const setStartEndTime = ({ timeSlot, currentStartDate }) => {
    const currentTime =
      !timeSlot || timeSlot === startTime ? startTime : timeSlot;
    const currentDate =
      !currentStartDate || currentStartDate === startDate
        ? startDate
        : currentStartDate;
    const [hour, minute] = currentTime.split(":");
    let startDateTime = currentDate;
    startDateTime.setHours(hour);
    startDateTime.setMinutes(minute);
    setStartDate(startDateTime);
    handleStateChange(
      "auction_start_at",
      Math.floor(startDateTime.getTime() / 1000)
    );
    startDateTime.setMinutes(parseInt(minute) + parseInt(duration));
    handleStateChange(
      "auction_end_at",
      Math.floor(startDateTime.getTime() / 1000)
    );
  };
  const selectAttr = {
    name: "Duration",
    label: "DURATION",
    color: "secondary",
    isRequired: true,
    index: 2,
    handleChange: (e) => {
      setDuration(e.value);
      // setStartEndTime();
    },
  };

  const selectData = [
    {
      id: 1,
      value: 5,
      name: "5 mins",
    },
    {
      id: 2,
      value: 10,
      name: "10 mins",
    },
    {
      id: 3,
      value: 15,
      name: "15 mins",
    },
    {
      id: 4,
      value: 30,
      name: "30 mins",
    },
    {
      id: 5,
      value: 45,
      name: "45 mins",
    },
    {
      id: 6,
      value: 60,
      name: "1 hour",
    },
    {
      id: 7,
      value: 120,
      name: "2 hours",
    },
    {
      id: 8,
      value: 180,
      name: "3 hours",
    },
  ];

  const handleStartDateChange = (name, value) => {
    setStartDate(new Date(value * 1000));
    setStartEndTime({ currentStartDate: new Date(value * 1000) });
  };

  const timeSlotAttr = {
    name: "timeSlot",
    label: "TIME_SLOT",
    color: "secondary",
    index: 0,
    isRequired: true,
    handleChange: (e) => {
      const { value } = e;
      setStartTime(value);
      setStartEndTime({
        timeSlot: value,
      });
    },
  };

  const timeSlotData = timeslots(duration);
  return (
    <>
      <Stack direction="column" sx={{ mt: 2 }}>
        <SelectComponent attr={selectAttr} data={selectData} />
      </Stack>
      <Stack direction="column" sx={{ mt: 2 }}>
        <InputButtonComponent attr={attrData} />
      </Stack>
      <Stack direction="column" sx={{ mt: 2 }}>
        <SingleDatePicker
          isMobile={isMobile}
          label={t("START_DATE")}
          name="startDate"
          handleChange={handleStartDateChange}
        />
      </Stack>
      <Stack direction="column">
        <SelectComponent attr={timeSlotAttr} data={timeSlotData} />
      </Stack>
      <Divider className={styles.dividerBox} />
    </>
  );
};

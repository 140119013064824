import {
    Grid,
    Stack,
    Typography,
    Switch,
  } from "@mui/material";
// import { useN01SwitchStyles } from '@mui-treasury/styles/switch/n01';
import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";

const SwitchComponent = ({
    name,
    darkMode,
    iconElement,
    labelText,
    description,
    activatedChildren,
    handleChange,
    autoChecked
  }) => {
    // const switchStyles = useN01SwitchStyles();
    const { t } = useTranslation();
    const [checked, setChecked]  = useState(autoChecked);
    useEffect(() => {
      setChecked(autoChecked);
    }, [autoChecked])
    const handleSwichChange = (e) => {
      setChecked(e.target.checked);
      if (handleChange) {
        handleChange(e.target.name, e.target.checked);
      }
    }

    return(
      <>
        <Stack
          direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        sx={{ mt: 1, mb: 1 }}
        >
        <Typography
            component="div"
            sx={{ display: "flex", alignItems: "center", gap: 2 }}
        >
            <Typography
            component="span"
            fontSize={18}
            mt={0.5}
            >
            {!iconElement ? (<></>) : (iconElement)}
            </Typography>
            <Stack direction="column" spacing={2}>
              <Box>
                <Typography
                variant="body2"
                component="label"
                sx={{
                    color: `${darkMode ? "#ffffff" : "#121212"}`,
                    fontSize: "14px",
                    fontWeight: "500",
                }}
                >
                {t(labelText)}
                </Typography>
              </Box>
              </Stack>
            </Typography>
          <Switch
            name={name}
            // classes={switchStyles}
            color="primary"
            checked={checked}
            onChange={handleSwichChange}
          >
          </Switch>
        </Stack>
        {!(activatedChildren && checked)  ? (<></>) : (
            <Grid
              container
              columns={{ md: 12 }}
              spacing={{ md: 4 }}
            >
              {activatedChildren})
            </Grid>
          )}
      </>
    );
  }

  export default SwitchComponent;